<template>
    <section>
        <div v-if="emptyHistory" class="tx-history-empty-panel" v-text="$t('address.tx_table.empty')" />

        <div v-show="!emptyHistory" class="tx-history-wrap desktop-table">
            <table class="tx-table">
                <thead v-if="!isMobile">
                    <tr>
                        <th v-if="!hideIcon" width="40"></th>
                        <th width="100">
                            <div class="tx-table__cell" v-text="$t('address.tx_table.age')" />
                        </th>
                        <th>
                            <div class="tx-table__cell tx-table__cell--align-right" v-text="$t('address.tx_table.from')" />
                        </th>
                        <th v-pre width="50"></th>
                        <th>
                            <div class="tx-table__cell" v-text="$t('address.tx_table.to')" />
                        </th>
                        <th class="column-wide">
                            <div class="tx-table__cell tx-table__cell--align-right" style="padding-right: 26px;"
                                v-text="$t('address.tx_table.value')" />
                        </th>
                        <th v-pre width="10"></th>
                    </tr>
                </thead>

                <template v-if="address">
                    <template v-for="tx in pendingTransactions">
                        <component
                            v-for="(msg, idx) in tx.messages"
                            v-bind:key="`pending_tx_${tx.hash}_msg_${idx}`"
                            v-bind:is="isMobile ? 'tx-row-mobile' : 'tx-row'"
                            v-bind:class="{ 'sub-list': idx > 0 }"
                            v-bind:address="address"
                            v-bind:txHash="tx.hash"
                            v-bind:txLt="tx.lt"
                            v-bind:timestamp="tx.timestamp"
                            v-bind:fee="tx.fee"
                            v-bind:exitCode="tx.exit_code"
                            v-bind:outputCount="tx.output_count"
                            v-bind:action="msg.action"
                            v-bind:event="msg.event"
                            v-bind:meta="msg.meta"
                            v-bind:hideIcon="hideIcon"
                            v-bind:pending="tx.is_pending"
                            v-bind="msg"
                            v-bind:msg="msg"
                            v-bind:encrypted="msg.encrypted"
                        />
                    </template>
                    <template v-for="tx in transactions">
                        <component
                            v-for="(msg, idx) in tx.messages"
                            v-bind:key="`tx_${tx.hash}_msg_${idx}`"
                            v-bind:is="isMobile ? 'tx-row-mobile' : 'tx-row'"
                            v-bind:class="{ 'sub-list': idx > 0 }"
                            v-bind:address="address"
                            v-bind:txHash="tx.hash"
                            v-bind:txLt="tx.lt"
                            v-bind:timestamp="tx.timestamp"
                            v-bind:fee="tx.fee"
                            v-bind:exitCode="tx.exit_code"
                            v-bind:outputCount="tx.output_count"
                            v-bind:action="msg.action"
                            v-bind:event="msg.event"
                            v-bind:meta="msg.meta"
                            v-bind:hideIcon="hideIcon"
                            v-bind="msg"
                            v-bind:msg="msg"
                            v-bind:encrypted="msg.encrypted"
                        />
                    </template>
                </template>

                <template v-if="!address || transactions.length === 0">
                    <component
                        v-bind:is="isMobile
                            ? 'tx-row-skeleton-mobile'
                            : 'tx-row-skeleton-events'"
                        v-for="i in 8"
                        v-bind:key="`tx_skeleton_${i}`"
                        v-bind:hide-icon="hideIcon"/>
                </template>
            </table>
        </div>

        <ui-mugen-scroll
            v-bind:handler="loadMore"
            v-bind:shouldHandle="shouldHandleScroll"
            v-bind:showButton="showPreloader"
            v-bind:isLoading="isLoading"/>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { getPendingActions, getToncenterActions } from '~/api/toncenterV3';
import TxRowSkeletonEvents from './TxRowSkeletonEvents.vue';
import TxRowSkeletonMobile from './TxRowSkeletonMobile.vue';
import TxRow from './TxRow.vue';
import TxRowMobile from './TxRowMobile.vue';

export default {
    pendingInterval: undefined,
    name: 'tx-events',
    props: {
        address: {
            type: String,
            required: false,
        },
        shouldLoadPendings: {
            type: Boolean,
        },
    },

    data() {
        return {
            transactions: [],
            pendingTransactions: [],
            isLoading: true,
            hasMore: true,
            emptyHistory: false,
            lastActivity: undefined,
            hideIcon: false,
            isUnmounted: false,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading && this.address && this.hasMore && this.transactions.length > 0;
        },

        showPreloader() {
            return this.address && this.transactions.length > 0 && this.hasMore;
        },

        ...mapState({ dataSource: 'txTableSource', actionTypeFilter: 'actionTypeFilter' }),
    },

    watch: {
        address: {
            immediate: true,
            handler() {
                this.resetData();
            },
        },

        actionTypeFilter: {
            immediate: false,
            handler() {
                this.removeInterval();
                this.resetData();
                this.startInterval();
            },
        },

        isMobile: {
            immediate: false,
            handler() {
                // this.removeInterval();
                // this.resetData();
            },
        },

        shouldLoadPendings(newValue) {
            if (newValue) {
                this.startInterval();
            } else {
                this.removeInterval();
            }
        },
    },

    activated() {
        this.emitLastActivity();
    },

    mounted() {
        document.addEventListener('visibilitychange', this.activeTabChange);
    },

    methods: {
        resetData() {
            if (!this.address) return;

            this.transactions = [];
            this.pendingTransactions = [];
            this.isLoading = true;
            this.hasMore = true;
            this.emptyHistory = false;
            this.isUnmounted = false;

            this.$nextTick(this.loadData);
        },

        activeTabChange() {
            const isTabActive = !document.hidden;

            if (isTabActive) {
                // If the tab became active we reset the table, to prevent duplicates
                this.resetData();
                this.startInterval();
            } else {
                // If the tab became inactive we stop the interval that is getting pending tx
                this.removeInterval();
            }
        },

        emitLastActivity() {
            this.$emit('lastActivityUpdate', this.lastActivity);
        },

        displayMsg(msg) {
            return msg && !msg?.is_external;
        },

        startInterval() {
            if (!this.$options.pendingInterval) {
                this.$options.pendingInterval = setTimeout(() => {
                    this.loadPendingTransactions();
                }, 5000);
            }
        },

        async loadData() {
            this.isLoading = true;
            const limit = 20;

            if (!this.address) return;

            try {
                const transactions = await getToncenterActions(this.address, { limit }, this.actionTypeFilter);
                const msgCount = transactions.reduce((sum, obj) => sum + (obj.messages.length || 0), 0);

                this.transactions = transactions;
                this.emptyHistory = !transactions.length;
                this.hasMore = msgCount >= limit;
                this.lastActivity = transactions.length ? transactions[0]?.timestamp : null;

                this.emitLastActivity();
            } catch (error) {
                console.error('Error loading transactions:', error);
            } finally {
                this.isLoading = false;
            }

            try {
                this.pendingTransactions = await getPendingActions(this.address);
            } catch (error) {
                console.error('Error loading transactions:', error);
            }
        },

        async loadPendingTransactions() {
            // Prevent recursive call of the function
            if (this.isUnmounted) return;
            // Bugfix for cases, when transactions[0].trace_end_lt hasn't loaded yet
            if (!this.transactions[0]) {
                this.removeInterval();
                this.$options.pendingInterval = setTimeout(() => {
                    this.loadPendingTransactions();
                }, 5000);
                return;
            }
            const trace_end_lt = this.transactions[0].trace_end_lt || this.transactions[0][0].trace_end_lt;
            try {
                const transactions = await getToncenterActions(this.address, {
                    start_lt: Number(trace_end_lt) + 1,
                }, this.actionTypeFilter);

                if (transactions.length) {
                    this.transactions.unshift(...transactions);
                    this.lastActivity = transactions.length ? transactions[0]?.timestamp : null;
                }

                this.emitLastActivity();
            } catch (error) {
                console.error('Error loading transactions:', error);
            } finally {
                this.isLoading = false;
            }

            try {
                this.pendingTransactions = await getPendingActions(this.address);
            } catch (error) {
                console.error('Error loading transactions:', error);
            }

            this.$options.pendingInterval = setTimeout(() => {
                this.loadPendingTransactions();
            }, 5000);
        },

        async loadMore() {
            if (this.isLoading || !this.hasMore) return;

            this.isLoading = true;
            const limit = 50;

            try {
                const newTx = await getToncenterActions(this.address, {
                    limit,
                    end_lt: this.transactions.at(-1).lt - 1,
                }, this.actionTypeFilter);

                const msgCount = newTx.reduce((sum, obj) => sum + (obj.messages.length || 0), 0);

                this.hasMore = msgCount >= limit;
                this.transactions = [...this.transactions, ...newTx];
            } catch (error) {
                console.error('Error loading more transactions:', error);
            } finally {
                this.isLoading = false;
            }
        },

        removeInterval() {
            clearTimeout(this.$options.pendingInterval);
            this.$options.pendingInterval = null;
        },
    },

    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.activeTabChange);
        this.removeInterval();
        this.isUnmounted = true;
    },

    components: {
        TxRow, TxRowMobile, TxRowSkeletonMobile, TxRowSkeletonEvents,
    },
};
</script>
