<template>
    <section>
        <div v-if="emptyHistory" class="tx-history-empty-panel">
            {{ $t('address.tokens.no_tokens') }}

            <a href="https://dedust.io/" class="user-nft__btn" target="_blank">
                {{ $t('address.tokens.buy_tokens') }}
            </a>
        </div>

        <div v-else class="tx-history-wrap">
            <table v-bind:class="isMobile
                ? 'ui-table ui-table-mobile'
                : 'ui-table'">
                <thead v-if="!isMobile">
                    <tr>
                        <th width="200">{{ $t('address.tokens.table_token_name') }}</th>
                        <th width="300" style="max-width: 300px;">{{ $t('address.tokens.table_token_balance') }}</th>
                        <th class="ui-table__cell--align-right">{{ $t('address.tokens.table_token_jetton_wallet') }}</th>
                    <!-- <th class="ui-table__cell--align-right">{{ $t('address.tokens.table_token_amount') }}</th> -->
                    </tr>
                </thead>
                <tbody v-if="isLoading">
                    <component
                        v-for="i in 8"
                        v-bind:is="isMobile
                            ? 'tab-token-skeleton-mobile'
                            : 'tab-token-skeleton'"
                        v-bind:key="`tx_skeleton_${i}`"
                    />
                </tbody>
                <tbody v-else>
                    <!-- <component
                    v-for="wallet in jettons"
                    v-bind:key="wallet.address"
                    v-bind:is="isMobile
                        ? 'tab-token-item-mobile'
                        : 'tab-token-item'"
                    v-bind:wallet="wallet"
                    v-bind:rates="rates"
                /> -->
                    <component
                        v-for="jetton in jettons"
                        v-bind:key="jetton.jetton"
                        v-bind:is="isMobile ? 'tab-token-item-mobile' : 'tab-token-item'"
                        v-bind:image="getImgProxy(jetton.jetton)"
                        v-bind:name="metadata[jetton.jetton]?.token_info[0]?.name || $t('address.contract_type.unknown')"
                        v-bind:symbol="truncateTicker(metadata[jetton.jetton]?.token_info[0]?.symbol) || shortenAddress(jetton.jetton)"
                        v-bind:balance="jetton.balance"
                        v-bind:decimals="jetton.decimals"
                        v-bind:jetton="addressBook[jetton.jetton].user_friendly"
                        v-bind:jetton-wallet="addressBook[jetton.address].user_friendly"
                        v-bind:jetton-price="jetton.jetton_price_usd"
                        v-bind:balance-price="jetton.balance_price_usd"
                    />
                </tbody>
            </table>
        </div>

        <div v-if="emptyJettons.length > 0 && !emptyJettonsVisible" class="block-empty-jettons" v-on:click="emptyJettonsVisible = true">
            {{ $t('address.tokens.show_empty_tokens', [emptyJettons.length]) }}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none"><path stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" d="m1.5 4.75 5.5 5.5 5.5-5.5"/></svg>
        </div>

        <div v-if="emptyJettonsVisible" class="tx-history-wrap">
            <table class="ui-table">
                <tr v-for="wallet in emptyJettons" v-bind:key="wallet.address">
                    <td>
                        <ui-link v-bind:to="{ name: 'jetton', params: { address: wallet.jetton_address }}" class="jetton-list-link">
                            <ui-round-image class="jetton-list__icon" size="tiny" v-bind:src="wallet.jetton_meta.image.w72"/>
                            {{ wallet.jetton_meta.name || wallet.jetton_address }}
                        </ui-link>
                    </td>
                    <td>
                        <b>{{ $ton(wallet.balance, wallet.jetton_meta.decimals) }}</b>
                        <span style="margin-left: 4px; opacity: .5">{{ wallet.jetton_meta.symbol }}</span>
                    </td>
                    <td class="ui-table__cell--align-right">
                        <ui-address v-bind:address="wallet.address"/>
                    </td>
                </tr>
            </table>
        </div>
        <ui-mugen-scroll
            v-bind:handler="loadMore"
            v-bind:shouldHandle="shouldHandleScroll"
            v-bind:showButton="showPreloader"
            v-bind:isLoading="isLoading"/>
    </section>
</template>

<script>
import { getJettonsByOwnerAddress } from '~/api/toncenterV3';
import { truncateTicker } from '~/helpers';
import TabTokenSkeleton from './TabTokenSkeleton.vue';
import TabTokenSkeletonMobile from './TabTokenSkeletonMobile.vue';
import TabTokenItem from './TabTokenItem.vue';
import TabTokenItemMobile from './TabTokenItemMobile.vue';

const limit = 20;

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            jettons: [],
            addressBook: [],
            metadata: [],
            emptyJettons: [],
            emptyJettonsVisible: false,
            isLoading: true,
            emptyHistory: false,
            rates: null,
            hasMore: false,
        };
    },
    created() {
        this.loadData();
    },
    computed: {
        shouldHandleScroll() {
            return !this.isLoading && this.address && this.hasMore && this.jettons.length > 0;
        },

        showPreloader() {
            return this.address && this.jettons.length > 0 && this.hasMore;
        },
    },
    methods: {
        truncateTicker,
        async loadData() {
            const jettonsList = await getJettonsByOwnerAddress(this.address, limit);

            if (jettonsList.jetton_wallets.length === 0) {
                this.emptyHistory = true;
                this.loading = false;
                return;
            }

            this.jettons = jettonsList.jetton_wallets;
            this.addressBook = jettonsList.address_book;
            this.metadata = jettonsList.metadata;

            this.isLoading = false;
            this.hasMore = jettonsList.jetton_wallets.length >= limit;
        },
        async loadMore() {
            const jettonsList = await getJettonsByOwnerAddress(this.address, 50, this.jettons.length);

            this.jettons = this.jettons.concat(jettonsList.jetton_wallets);
            this.addressBook = Object.assign(this.addressBook, jettonsList.address_book);
            this.metadata = Object.assign(this.metadata, jettonsList.metadata);

            this.hasMore = jettonsList.jetton_wallets.length >= 50;
        },
        shortenAddress(address) {
            const jettonAddress = this.addressBook[address].user_friendly;
            return jettonAddress.slice(0, 4) + '...' + jettonAddress.slice(44);
        },
        getImgProxy(jetton) {
            const metadata = this.metadata[jetton]?.token_info[0];
            const proxyUrl = metadata?.extra?._image_small; // eslint-disable-line no-underscore-dangle
            const fallbackUrl = metadata?.image;

            return proxyUrl || fallbackUrl;
        },
    },

    components: {
        TabTokenSkeleton,
        TabTokenSkeletonMobile,
        TabTokenItem,
        TabTokenItemMobile,
    },
};
</script>

<style lang="scss">
.jetton-list__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    &--rastopyrka {
        visibility: hidden;
        height: 0;
    }
}

.jetton-list-link {
    display: flex;
    align-items: center;
}

.tx-token-cell {
    display: flex;
    align-items: center;
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
}

.ui-table-mobile {
    table-layout: fixed;
}

.ui-table-mobile td {
    padding: 0!important;
    border-top: 0!important;
}

.ui-table-mobile .tx-mobile-content {
    border-bottom: 1px solid var(--card-row-separator);
    padding: 1em 14px 3px 5px;
}

.ui-table-mobile tr:last-child .tx-mobile-content {
    border-bottom: none;
}
</style>
