<template>
    <div class="market-filter-wrapper">
        <div class="market-filter">
            <div
                v-for="category in categories"
                class="market-filter__item"
                v-bind:class="{
                    'market-filter__active': category === activeCategory,
                }"
                v-on:click="selectCategory(category)"
                v-bind:key="category"
            >
                <span>{{ $t(`jetton_filters.${category}`) }}</span>
            </div>
            <dyor-logo />
        </div>
    </div>
</template>

<script>
import DyorLogo from '~/components/market/DyorLogo.vue';

export default {
    data() {
        return {
            categories: ['all', 'jettons', 'marketcap', 'stable', 'taponomics'],
            activeCategory: 'jettons',
        };
    },

    mounted() {
        this.activeCategory = this.$route.query?.sort || 'jettons';
    },

    methods: {
        selectCategory(category) {
            // this.activeCategory = this.$route.query?.sort;
            if (this.activeCategory !== category) {
                this.activeCategory = category;
                if (this.activeCategory === 'jettons' || !this.categories.includes(this.activeCategory)) {
                    this.$router.replace(this.$localizeRoute({ name: 'market', query: { sort: undefined } }));
                } else {
                    this.$router.replace(this.$localizeRoute({ name: 'market', query: { sort: category } }));
                }
                this.$emit('change-jetton-category', category);
            }
        },
    },

    components: {
        DyorLogo,
    },
};
</script>

<style lang="scss">
.market-filter-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: var(--card-background);
    border: 1px solid var(--card-border-color);
    margin-bottom: 0;
    position: relative;
    z-index: 99;
    padding: 10px;
    border-radius: 12px 12px 0 0;
    border-bottom: 0;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.market-filter-wrapper::-webkit-scrollbar {
    display: none;
}

.market-filter {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 0px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;

    &__item {
        line-height: 35px;
        margin-top: 0 !important;
        cursor: pointer;
        padding: 0 14px;
        color: var(--body-muted-text-color);
        font-weight: 500;
        background-color: var(--tx-table-thead-alt-background) !important;
        box-sizing: border-box;
        position: relative;
        border-radius: 8px;
        font-size: 14px;
        user-select: none;
    }

    &__item:last-child {
        margin-right: 10px;
    }

    &__item::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid var(--card-border-color);
    }

    &__active {
        color: var(--body-text-color);
    }

    &__active::after {
        border: 2px solid var(--filter-item-active) !important;
    }
}
</style>
