import { GETGEMS_GRAPHQL_ENDPOINT } from '~/config.js';
import axios from 'axios';

/* eslint camelcase: "off", func-names: "off" */

const http = axios.create({
    baseURL: GETGEMS_GRAPHQL_ENDPOINT,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Method': 'POST',
    },
});

const GetUserItemsQuery = `
query NftItemConnection($ownerAddress: String!, $first: Int!, $after: String) {
  nftItemsByOwner(ownerAddress: $ownerAddress, first: $first, after: $after) {
    cursor
    items {
      id
      name
      address
      index
      kind
      image: content {
        type: __typename
        ... on NftContentImage {
          originalUrl
          thumb: image {
            sized(width: 480, height: 480)
          }
        }
        ... on NftContentLottie {
          preview: image {
            sized(width: 480, height: 480)
          }
        }
        ... on NftContentVideo {
          cover: preview(width: 480, height: 480)
        }
      }
      collection {
        address
        name
        isVerified
      }
      sale {
        ... on NftSaleFixPrice {
          fullPrice
        }
      }
    }
  }
}`;

const GetItemHistoryQuery = `
query historyNftItem($address: String!, $count: Int!, $cursor: String, $types: [HistoryType!]) {
  historyNftItem(address: $address, first: $count, after: $cursor, types: $types) {
    cursor
    items {
      ...nftHistoryItem
    }
  }
}

fragment nftHistoryItem on NftItemHistory {
  __typename
  time
  hash
  typeData {
    __typename
    ... on HistoryTypeMint {
      type
      __typename
    }
    ... on HistoryTypeTransfer {
      __typename
      type
      oldOwner
      newOwner
    }
    ... on HistoryTypeCancelSale {
      __typename
      type
      owner
      price
    }
    ... on HistoryTypeSold {
      __typename
      type
      oldOwner
      newOwner
      price
    }
    ... on HistoryTypePutUpForSale {
      __typename
      type
      owner
      price
    }
    ... on HistoryTypeCancelAuction {
      __typename
      type
      historyType
      owner
    }
    ... on HistoryTypePutUpForAuction {
      __typename
      type
      historyType
      owner
    }
  }
}`;

const GetNftCollectionStats = `
query ExampleQuery($address: String!) {
  alphaNftCollectionStats(address: $address) {
    floorPrice
    totalVolume
    totalVolumeSold
  }
  statsNftCollection(collectionAddress: $address) {
    holders
  }
}
`;

const GetNftItemMetadata = `
query metadataNftItem($address: String!) {
   alphaNftItemByAddress(address: $address) {
    name,
    index,
    content {
      ... on NftContentImage {
        originalUrl
          thumb: image {
            sized(width: 480, height: 480)
          }
      }
      ... on NftContentVideo {
        video: baseUrl
        preview(width: 480, height: 480)
      }
       ... on NftContentLottie {
        animated: image {
          baseUrl
        }
      }
    }
    description
    address
    collection {
      address
    }
    ownerAddress
    attributes {
      traitType
      value
    }
    collection {
      address
      name
      itemsCount: approximateItemsCount
      image {
        url: originalUrl
        thumb: image {
            sized(width: 480, height: 480)
        }
      }
    }
  }
}
`;

const getCollectionNftItems = `
query NftCollectionItems($address: String!, $first: Int!, $after: String) {
  nftCollectionItems(address: $address, first: $first, after: $after) {
    items {
      item_address: address
      index
      owner_address: ownerAddress
      name
      description
      content {
        ... on NftContentImage {
          originalUrl
            thumb: image {
              sized(width: 480, height: 480)
            }
        }
        ... on NftContentVideo {
          video: baseUrl
          preview(width: 480, height: 480)
        }
        ... on NftContentLottie {
          animated: image {
            baseUrl
          }
        }
      }
    }
    cursor
  }
}
`;

/**
 * @param  {String} owner
 * @param  {Number} first
 * @param  {String} after
 * @return {Promise<Object>}
 */
export const nftItemsByOwner = async function (owner, first = 24, after = undefined) {
    const { data: { data: { nftItemsByOwner: { cursor, items } } } } = await http.post(null, {
        query: GetUserItemsQuery,
        variables: {
            ownerAddress: owner,
            first, after,
        },
    });

    return { cursor, items };
};

export const getItemHistory = async function (address, count = 24, cursor = undefined) {
    const { data: { data: { historyNftItem } } } = await http.post(null, {
        variables: { address, count, cursor },
        query: GetItemHistoryQuery,
    });

    return historyNftItem;
};

export const getNftCollection = async function (address) {
    const { data: { data: { alphaNftCollectionStats, statsNftCollection } } } = await http.post(null, {
        variables: { address },
        query: GetNftCollectionStats,
    });

    return {
        ...alphaNftCollectionStats,
        ...statsNftCollection,
    };
};

export const getCollectionItems = async function (address, limit = 12, cursor = undefined) {
    const { data: { data: { nftCollectionItems } } } = await http.post(null, {
        variables: { address, first: limit, after: cursor },
        query: getCollectionNftItems,
    });

    return nftCollectionItems;
};

export const getItemMetadata = async function (address) {
    const { data: { data: { alphaNftItemByAddress } } } = await http.post(null, {
        variables: { address },
        query: GetNftItemMetadata,
    });

    return alphaNftItemByAddress;
};
