<template>
    <component v-bind:is="getIcon" />
</template>

<script>
import IconIn from '@img/icons/tonscan/transactions/tx-in.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconBurn from '@img/icons/tonscan/transactions/tx-burn.svg?vue';
import IconJettonMint from '@img/icons/tonscan/transactions/tx-jetton-mint.svg?vue';
import IconNftIn from '@img/icons/tonscan/transactions/tx-nft-in.svg?vue';
import IconNftOut from '@img/icons/tonscan/transactions/tx-nft-out.svg?vue';
import IconNftBuy from '@img/icons/tonscan/transactions/tx-nft-buy.svg?vue';
import IconNftMint from '@img/icons/tonscan/transactions/tx-nft-mint.svg?vue';
import IconSwap from '@img/icons/tonscan/transactions/tx-swap.svg?vue';
import IconContractDeploy from '@img/icons/tonscan/transactions/tx-contract-deploy.svg?vue';
import IconStakeIn from '@img/icons/tonscan/transactions/tx-stake-in.svg?vue';
import IconStakeOut from '@img/icons/tonscan/transactions/tx-stake-out.svg?vue';
import IconStakeDeposit from '@img/icons/tonscan/transactions/tx-stake-deposit.svg?vue';
import IconStakeWithdraw from '@img/icons/tonscan/transactions/tx-stake-withdraw.svg?vue';
import IconAuctionBid from '@img/icons/tonscan/transactions/tx-bid.svg?vue';
import IconSubscribe from '@img/icons/tonscan/transactions/tx-sub.svg?vue';
import IconUnsubscribe from '@img/icons/tonscan/transactions/tx-unsub.svg?vue';
import IconChain from '@img/icons/tonscan/chain-16.svg?vue';
import IconDomainRenew from '@img/icons/tonscan/domain_renew.svg?vue';

/* eslint-disable "quote-props" */
const iconMap = Object.freeze({
    ton_transfer: IconOut,
    received_ton: IconIn,
    sent_ton: IconOut,
    jetton_transfer: IconOut,
    received_jetton: IconIn,
    sent_jetton: IconOut,
    auction_bid: IconAuctionBid,
    contract_deploy: IconContractDeploy,
    call_contract: IconContractDeploy,
    deploy_nft: IconNftMint,
    nft_transfer: IconNftOut,
    stake_deposit: IconStakeDeposit,
    renew_dns: IconDomainRenew,
    election_deposit: IconStakeOut,
    election_recover: IconStakeIn,
    jetton_mint: IconJettonMint,
    jetton_burn: IconBurn,
    jetton_swap: IconSwap,
    nft_purchase: IconNftBuy,
    nft_mint: IconNftBuy,
    received_nft: IconNftIn,
    sent_nft: IconNftOut,
    subscribe: IconSubscribe,
    unsubscribe: IconUnsubscribe,
    stake_withdrawal: IconStakeWithdraw,
    stake_withdrawal_request: IconStakeIn,
    change_dns: IconDomainRenew,
    delete_dns: IconDomainRenew,
    dex_deposit_liquidity: IconStakeDeposit,
    dex_withdraw_liquidity: IconStakeWithdraw,
});
export default {
    props: {
        event: String,
    },

    computed: {
        getIcon() {
            return iconMap[this.event] || IconChain;
        },
    },
};
</script>

<style lang="scss">
.tx-overview-action-type{
    display: flex;
    align-items: center;
    margin-right: 12px;

    &__icon {
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-right: 6px;
    }
}
</style>
