<template>
    <ui-link class="ui-inline-nft" v-bind:to="routerParams" v-bind:class="{'ui-inline-nft--green': isMobile && !isOut}">
        <div class="ui-inline-nft__left">
            <div class="ui-inline-nft-image">
                <img class="ui-inline-nft-image__img" v-bind:src="imageSrc" v-show="!hasError" v-on:error="hasError = true" alt="">
                <icon-nft class="ui-inline-nft-image__svg"/>
            </div>
        </div>
        <div v-if="eventType && nftName" class="ui-inline-nft__title">
            <p>{{ getNftName }}</p>
            <p v-if="isMobile" v-bind:class="isMobile && !isOut ? 'ui-inline-nft__collection--green' : 'ui-inline-nft__collection'">
                <span v-if="collectionName">{{ collectionName }}</span>
                <span v-else>{{ $t('address.nfts.single_nft') }}</span>
            </p>
        </div>
        <div v-else class="ui-inline-nft__title">
            <p>{{ $t('common.nft_item') }}</p>
            <p v-if="isMobile" v-bind:class="isMobile && !isOut ? 'ui-inline-nft__collection--green' : 'ui-inline-nft__collection'">
                <span v-if="collectionName">{{ collectionName }}</span>
                <span v-else>{{ $t('address.nfts.single_nft') }}</span>
            </p>
        </div>
    </ui-link>
</template>

<script>
import IconNft from '@img/icons/tonscan/nft-14.svg?inline';

export default {
    props: {
        address: String,
        eventType: String,
        imageSrc: String,
        nftName: String,
        collectionName: String,
        isOut: Boolean,
    },

    data() {
        return {
            hasError: false,
        };
    },

    computed: {
        getNftName() {
            if (this.nftName.length > 22) {
                return this.nftName.substring(0, 10) + '...' + this.nftName.substring(this.nftName.length - 10);
            }
            return this.nftName;
        },
        routerParams() {
            return {
                name: 'nft',
                params: {
                    address: this.address,
                    skeletonHint: 'item',
                },
            };
        },
    },

    components: {
        IconNft,
    },
};
</script>

<style lang="scss">
.ui-inline-nft {
    display: flex;
    align-items: center;
    background: var(--nft-preview-background);
    border-radius: 8px;
    margin: -4px 0;
    color: inherit;
    transition: .2s;
    &__left {
        width: 26px;
        height: 26px;
        border-radius: 8px 0 0 8px;
        overflow: hidden;
    }
    &__title {
        padding: 5px 10px;
        font-size: 13px;
        p {
            margin: 0;
            padding: 0;
        }
    }
    &__collection {
        margin: 0;
        font-size: 12px;
        color: var(--body-muted-text-color);
    }
    &:hover {
        background: var(--nft-preview-hover-background);
        color: var(--nft-preview-hover-color);
        text-decoration: none;
    }
}

@media screen and (max-width: 600px) {
    .ui-inline-nft{
        display: inline-flex;
        border-radius: 8px!important;
        padding: 0!important;
        margin: 8px 0 0 0!important;

        &__title {
            padding-right: 14px!important;
        }

        &__left {
            width: 52px;
            height: 52px;
        }
        &__collection--green {
            color: var(--chart-line-green-color);
            //opacity: .7;
        }
        &--green {
            color: var(--tx-table-icon-in-color);
            background-color: var(--tx-table-icon-in-background);
            border-radius: 8px !important;
        }
        &:hover {
            background: var(--nft-preview-hover-background);
            color: var(--body-text-color);
        }
    }
}

.ui-inline-nft-image {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--card-border-color);
    color: var(--body-text-color);
    &__img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000;
        transform: scale(1.1);
    }
    &__svg {
        width: 14px;
        height: 14px;
        opacity: 0.8;
        z-index: 100;
    }
}
</style>
