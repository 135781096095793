import { TONAPI_ENDPOINT, TONAPI_KEY } from '~/config.js';
import axios from 'axios';

/**
 * @see https://docs.tonconsole.com/tonapi/api-v2
 */
const http = axios.create({
    baseURL: TONAPI_ENDPOINT,
    headers: {
        Authorization: `Bearer ${TONAPI_KEY}`,
    },
});

export const getJettonChart = async (params) => {
    try {
        const response = await http.get('rates/chart', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const getJettonHoldersTonapi = async function getJettonHoldersByAddress(address, params) {
    const { data: response } = await http.get(`jettons/${address}/holders`, {
        params,
    });

    return response?.addresses;
};
