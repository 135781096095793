<template>
    <div>
        <template v-if="[
            'jetton:burn',
            'jetton:burn_notification',
            'jetton:transfer',
            'jetton:transfer_notification',
            'jetton:internal_transfer',
            'jetton:swap',
            'jetton:route_tonapi',
        ].includes(action.type)">
            <div v-if="action.type === 'jetton:swap'" class="mobile-swap mobile-swap--red">
                <span v-if="meta.jetton_in_address !== null" class="ui-inline-jetton__link ui-inline-jetton--red" v-bind:to="{ name: 'jetton', params: { address: meta.jetton_in_address }}">
                    <ui-inline-jetton
                        v-bind:isOut="true"
                        v-bind:address="meta.jetton_in_address"
                        v-bind:value="meta.amount_in"
                        v-bind:symbol="meta.symbol_in || meta.jetton_in_address"
                        v-bind:decimals="meta.decimals_in"
                        show-sign
                    />
                </span>
                <span v-else>
                    &minus;{{$ton(meta.amount_in, meta.decimals_in, true)}} TON
                </span>
            </div>

            <ui-inline-jetton v-else
                v-bind:isOut="is_out"
                v-bind:address="meta.jetton_address"
                v-bind:value="action.amount"
                v-bind:symbol="meta?.symbol || meta?.jetton_address"
                v-bind:decimals="meta.decimals"
                v-bind="meta.jetton"
            />
        </template>

        <!-- NFT transfer: -->
        <!--<ui-inline-nft-item-->
        <!--    v-else-if="action.type === 'nft:transfer_action'"-->
        <!--    v-bind:address="action.nft"-->
        <!--    v-bind:event-type="event"-->
        <!--    v-bind:image-src="meta.image"-->
        <!--    v-bind:nft-name="meta.name"-->
        <!--    v-bind:collection-name="meta.collection"-->
        <!--/>-->

        <!-- dex liquidity actions: -->
        <template v-else-if="action.type.startsWith('dex:')">
            <span v-if="meta.jetton_address === null">
                {{ $ton(meta.amount, meta.decimals) }} TON
            </span>
            <ui-inline-jetton
                v-else
                v-bind:isOut="is_out"
                v-bind:address="meta.jetton_address"
                v-bind:value="meta.amount"
                v-bind:symbol="meta?.symbol || meta?.jetton_address"
                v-bind:decimals="meta.decimals"
                v-bind:show-sign="txView === 'simple'"
                style="color: var(--body-text-color)"
            />
        </template>

        <!-- nominator pool actions: -->
        <template
            v-else-if="action.type.startsWith('pool:')">
            <template v-if="action.type === 'pool:new_stake_ok'">
                <span class="tx-row-msg-action tx-row-msg-action--single">
                    Stake accepted
                </span>
            </template>

            <template v-else>
                {{$ton(amount, undefined, true)}} TON
            </template>
        </template>

        <span v-else class="muted">
            {{$ton(amount)}} TON
        </span>
    </div>
</template>

<script>
import { truncateTicker } from '~/helpers';
import UiInlineJetton from './TxRowInlineJetton.vue';

export default {
    methods: { truncateTicker },
    props: {
        action: Object,
        amount: String,
        is_out: Boolean,
        meta: {
            type: Object,
            default: () => ({}),
        },
        from: String,
        to: String,
        event: String,
    },
    components: {
        UiInlineJetton,
    },
};
</script>

<style lang="scss">
.mobile-swap {
    text-transform: uppercase;

    &--green {
        color: var(--badge-green-color);
    }
    &--red {
        color: var(--badge-red-color);
    }
}
</style>
