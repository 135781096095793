<template>
    <section>
        <div class="tx-row-msg-action-badge" style="margin-bottom: 1px" v-if="(type === 'nft_transfer' || type === 'nft_mint') && !isMobile">
            <div v-if="actionDetails?.event === 'nft_purchase'" style="margin-bottom: -1px;margin-right: 5px">
                {{$ton(actionDetails?.amount)}} TON <icon-arrow-right class="swap-icon-arrow" style="margin-left: 1px" />
            </div>

            <ui-inline-nft-item
                style="transform: translateY(-1px)"
                v-bind:address="actionDetails.action.nft"
                v-bind:event-type="type"
                v-bind:image-src="actionDetails.meta?.image"
                v-bind:nft-name="actionDetails.meta.name"
                v-bind:collection-name="actionDetails.meta?.collection"
            />
        </div>
        <div v-else-if="(type === 'nft_transfer' || type === 'nft_mint') && isMobile">
            1 NFT
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'jetton_burn'">
            <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.action.amount, actionDetails.meta.decimals) }}</span>
            <ui-link
                v-bind:to="{ name: 'jetton', params: {
                    address: canonizeAddress(actionDetails.meta.jetton_address),
                }
                }">
                {{ actionDetails.meta.symbol || shortenAddress(actionDetails.meta.jetton_address) }}
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'jetton_transfer'">
            <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.action.amount, actionDetails.meta.decimals) }}</span>
            <ui-link
                v-bind:to="{ name: 'jetton', params: {
                    address: actionDetails.meta.jetton_address
                }
                }">
                {{ actionDetails.meta.symbol || shortenAddress(actionDetails.meta.jetton_address) }}
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'jetton_mint'">
            <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.amount, actionDetails.meta.decimals) }}</span>
            <ui-link
                v-bind:to="{ name: 'jetton', params: {
                    address: actionDetails.meta.jetton_address
                }
                }">
                {{ actionDetails.meta.symbol || shortenAddress(actionDetails.meta.jetton_address) }}
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'jetton_swap'">
            <inline-swap v-bind:meta="actionDetails.meta" v-bind:round="false" />
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'renew_dns'">
            <span>{{ actionDetails?.meta?.domain }}</span>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="type === 'dex_deposit_liquidity' || type === 'dex_withdraw_liquidity'">
            <template v-if="actionDetails.meta.jetton_address">
                <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.meta.amount, actionDetails.meta.decimals) }}</span>
                <ui-link
                    v-bind:to="{ name: 'jetton', params: {
                        address: canonizeAddress(actionDetails.meta.jetton_address),
                    }
                    }">
                    {{ actionDetails.meta.symbol || shortenAddress(actionDetails.meta.jetton_address) }}
                </ui-link>
            </template>
            <span v-else>
                {{ $ton(actionDetails.meta.amount) }} TON
            </span>
        </div>
        <div class="tx-row-tx-event-action-badge" v-else-if="actionDetails?.amount">
            <span>{{$ton(actionDetails.amount)}} TON</span>
        </div>
    </section>
</template>

<script>
import { canonizeAddress } from '~/tonweb.js';
import { formatSwap } from '~/helpers.js';
import UiInlineNftItem from '~/components/address/TxRowInlineNft.vue';
import InlineSwap from '~/components/address/TxRowInlineSwap.vue';
import IconArrowRight from '@img/icons/tonscan/arrow-small-right.svg?inline';

export default {
    props: {
        type: String,
        actionDetails: Object,
    },

    data() {
        return {
            address: null,
            hasError: false,
        };
    },

    methods: {
        canonizeAddress,
        formatSwap,
        setFriendlyAddress() {
            if (this.actionDetails && this.actionDetails.nft) {
                const canonizedAddress = canonizeAddress(this.actionDetails.nft, {
                    type: undefined,
                });

                if (canonizedAddress) {
                    this.address = canonizedAddress;
                }
            }
        },

        shortenAddress(address) {
            if (!address) return '';
            return address.slice(0, 3) + '...' + address.slice(-3);
        },
    },

    created() {
        this.setFriendlyAddress();
    },

    components: {
        InlineSwap,
        UiInlineNftItem,
        IconArrowRight,
    },
};
</script>

<style lang="scss">
.tx-row-tx-event-action-badge {
    display: flex;
    align-items: center;
}

@media (max-width: 599px) {
    .ui-inline-nft-mobile-swap {
        transform: none!important;
        max-height: 30px;
    }
}
</style>
