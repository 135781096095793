var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[([
        'jetton:burn',
        'jetton:burn_notification',
        'jetton:transfer',
        'jetton:transfer_notification',
        'jetton:internal_transfer',
        'jetton:swap',
        'jetton:route_tonapi',
    ].includes(_vm.action.type))?[(_vm.action.type === 'jetton:swap')?_c('div',{staticClass:"mobile-swap mobile-swap--red"},[(_vm.meta.jetton_in_address !== null)?_c('span',{staticClass:"ui-inline-jetton__link ui-inline-jetton--red",attrs:{"to":{ name: 'jetton', params: { address: _vm.meta.jetton_in_address }}}},[_c('ui-inline-jetton',{attrs:{"isOut":true,"address":_vm.meta.jetton_in_address,"value":_vm.meta.amount_in,"symbol":_vm.meta.symbol_in || _vm.meta.jetton_in_address,"decimals":_vm.meta.decimals_in,"show-sign":""}})],1):_c('span',[_vm._v("\n                −"+_vm._s(_vm.$ton(_vm.meta.amount_in, _vm.meta.decimals_in, true))+" TON\n            ")])]):_c('ui-inline-jetton',_vm._b({attrs:{"isOut":_vm.is_out,"address":_vm.meta.jetton_address,"value":_vm.action.amount,"symbol":_vm.meta?.symbol || _vm.meta?.jetton_address,"decimals":_vm.meta.decimals}},'ui-inline-jetton',_vm.meta.jetton,false))]:(_vm.action.type.startsWith('dex:'))?[(_vm.meta.jetton_address === null)?_c('span',[_vm._v("\n            "+_vm._s(_vm.$ton(_vm.meta.amount, _vm.meta.decimals))+" TON\n        ")]):_c('ui-inline-jetton',{staticStyle:{"color":"var(--body-text-color)"},attrs:{"isOut":_vm.is_out,"address":_vm.meta.jetton_address,"value":_vm.meta.amount,"symbol":_vm.meta?.symbol || _vm.meta?.jetton_address,"decimals":_vm.meta.decimals,"show-sign":_vm.txView === 'simple'}})]:(_vm.action.type.startsWith('pool:'))?[(_vm.action.type === 'pool:new_stake_ok')?[_c('span',{staticClass:"tx-row-msg-action tx-row-msg-action--single"},[_vm._v("\n                Stake accepted\n            ")])]:[_vm._v("\n            "+_vm._s(_vm.$ton(_vm.amount, undefined, true))+" TON\n        ")]]:_c('span',{staticClass:"muted"},[_vm._v("\n        "+_vm._s(_vm.$ton(_vm.amount))+" TON\n    ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }