<template>
    <tbody>
        <tr class="new-row-animation">
            <td class="tx-mobile-flex-content" colspan="10">
                <ui-link v-if="!hideIcon" class="tx-table-cell-icon"
                    v-bind:to="transactionRoute"
                    v-bind:title="!is_success ? $t('tx.error_icon_tooltip', [exitCode]) : undefined"
                    v-bind:class="{
                        'tx-table-cell-icon--error': (!is_success || is_bounced) && !unsuccessfulBid && !pending,
                        'tx-table-cell-icon--in': !isOut && !unsuccessfulBid && !pending,
                        'tx-table-cell-icon--out': isOut && !unsuccessfulBid && !pending,
                        'tx-table-cell-icon--blue': (is_swapped && is_success && !pending) || [
                            'subscribe',
                            'unsubscribe',
                            'jetton_mint',
                            'deploy_nft',
                        ].includes(event),
                        'tx-table-cell-icon--gray': unsuccessfulBid || pending,
                    }">

                    <icon-tx-bounced v-if="is_bounced"/>

                    <icon-tx-pending v-once v-else-if="pending" class="tx-pending-icon" />

                    <icon-tx-error v-else-if="!is_success"/>

                    <action-icon-mobile
                        v-else-if="action || event"
                        v-bind:actionName="action?.type"
                        v-bind:eventName="event"
                        v-bind:amount="amount"
                        v-bind:isOut="isOut"/>

                    <template v-else>
                        <icon-out v-if="isOut"/>
                        <icon-in v-else/>
                    </template>
                </ui-link>

                <div v-else class="tx-table-cell-icon"
                    v-bind:to="transactionRoute"
                    v-bind:title="!is_success ? $t('tx.error_icon_tooltip', [exitCode]) : undefined"
                    v-bind:class="{
                        'tx-table-cell-icon--error': !is_success || is_bounced,
                        'tx-table-cell-icon--in': !isOut,
                        'tx-table-cell-icon--out': isOut,
                        'tx-table-cell-icon--blue': (is_swapped && is_success) || [
                            'subscribe',
                            'unsubscribe',
                            'jetton_mint',
                            'deploy_nft',
                        ].includes(event),
                    }">

                    <icon-tx-bounced v-if="is_bounced"/>

                    <icon-tx-error v-else-if="!is_success"/>

                    <action-icon-mobile
                        v-else-if="action || event"
                        v-bind:actionName="action?.type"
                        v-bind:eventName="event"
                        v-bind:amount="amount"
                        v-bind:isOut="isOut"/>

                    <template v-else>
                        <icon-out v-if="isOut"/>
                        <icon-in v-else/>
                    </template>
                </div>

                <div class="tx-mobile-content">
                    <div class="tx-mobile-content__top">
                        <!-- Top row: date and amount: -->
                        <div class="tx-mobile-content-row">
                            <!-- Date is on the left: -->
                            <ui-timeago v-if="!pending" class="tx-mobile-content__time muted" v-bind:timestamp="timestamp"/>
                            <span v-else class="tx-mobile-content__time muted">
                                {{ $t('address.tx_table.pending') }}
                            </span>

                            <!-- Action description or amount is on the right: -->
                            <template>
                                <!-- Service actions: -->
                                <div v-if="is_service || is_external || isLog" class="muted" style="transform: translateY(50%)">
                                    {{$t(`common.empty`)}}
                                </div>

                                <!-- tonscan api action badge: -->
                                <action-badge-mobile
                                    v-else-if="action && event !== 'nft_purchase' && event !== 'nft_transfer'"
                                    v-bind:action="action"
                                    v-bind:amount="amount"
                                    v-bind:is_out="isOut"
                                    v-bind:from="from"
                                    v-bind:meta="meta"
                                    v-bind:to="to"
                                    v-bind:event="event"/>

                                <!-- Reverse coloring due to nft_purchase mechanic -->
                                <div v-else-if="event === 'nft_purchase'">
                                    {{$ton(amount)}} TON
                                </div>

                                <div v-else-if="event === 'nft_transfer'">
                                    1 NFT
                                </div>

                                <div v-else-if="[
                                         'jetton_burn',
                                         'jetton_mint',
                                         'auction_bid',
                                     ].includes(event)"
                                    v-bind:class="{
                                        'tx-amount--in': !isOut && !unsuccessfulBid,
                                        'tx-amount--out': isOut && !unsuccessfulBid,
                                        'muted': unsuccessfulBid,
                                    }">
                                    {{$ton(amount, meta?.decimals ?? 9)}} {{meta?.jetton?.symbol || meta?.symbol}}
                                </div>

                                <!-- events without TON amount: -->
                                <div class="muted" style="transform: translateY(50%)"
                                    v-else-if="[
                                        'unsubscribe',
                                        'external',
                                        'stake_withdrawal_request',
                                    ].includes(event) ||
                                        event === 'contract_deploy' && !amount
                                    ">
                                    {{$t(`events.${event}`)}}
                                </div>

                                <template v-else-if="event && event.endsWith('_dns') && meta?.domain">
                                    <ui-link
                                        style="margin-left: 5px;"
                                        v-bind:to="{ name: 'address', params: {
                                            address: to,
                                        }}">
                                        {{truncateDomain(meta.domain)}}
                                    </ui-link>
                                </template>

                                <!-- TON transfers: -->
                                <div v-else v-bind:class="{
                                    'tx-amount--in': !isOut,
                                    'tx-amount--out': isOut,
                                }">{{$ton(amount)}} TON</div> <!-- No line breaks to avoid whitespaces -->
                            </template>
                        </div>

                        <!-- Bottom row: address and action: -->
                        <div class="tx-mobile-content-row">
                            <!-- Transaction account is on the left: -->
                            <template>
                                <span v-if="is_external || event === 'external'" class="muted">
                                    {{$t('address.tx_table.external')}}
                                </span>

                                <ui-link v-else-if="is_aggregated" v-bind:to="transactionRoute">
                                    {{$tc('address.tx_table.address_count', outputCount)}}
                                </ui-link>

                                <template v-else-if="isLog">
                                    {{$t('address.tx_table.nowhere')}}
                                </template>

                                <ui-address v-else-if="!isOut"
                                    v-bind:address="sender"
                                    v-bind:type="source_type"
                                    v-bind:alias="$store.getters.getLocalAddressbookName(sender) || source_alias"/>

                                <ui-address v-else
                                    v-bind:address="receiver"
                                    v-bind:type="destination_type"
                                    v-bind:alias="$store.getters.getLocalAddressbookName(receiver) || destination_alias"/>
                            </template>

                            <!-- Transaction action is on the right: -->
                            <div class="muted" style="padding-left: 12px">
                                <!-- tonscan api actions: -->
                                <template v-if="action">
                                    <span v-if="action.type.endsWith(':excesses')">
                                        Excess
                                    </span>

                                    <span v-else-if="event === 'nft_purchase'">
                                        <template v-if="isOut">{{$t('events.nft_sold')}}</template>
                                        <template v-else>{{$t('events.nft_purchase')}}</template>
                                    </span>

                                    <span v-else-if="action?.type === 'nft:transfer_action'">
                                        <template v-if="isOut">{{$t('events.sent_nft')}}</template>
                                        <template v-else>{{$t('events.received_nft')}}</template>
                                    </span>

                                    <!-- dex liquidity actions: -->
                                    <template v-else-if="action.type.startsWith('dex:')">
                                        {{$t(`events.${event}`)}}
                                    </template>

                                    <!-- nominator pool actions: -->
                                    <template v-else-if="action.type.startsWith('pool:')">
                                        <template v-if="action.type === 'pool:recover_stake'">
                                            Recover stake
                                        </template>

                                        <template v-else-if="action.type === 'pool:recover_stake_ok'">
                                            Stake recovered
                                        </template>

                                        <template v-else-if="action.type === 'pool:new_stake'">
                                            <template v-if="amount > 5000000000">
                                                Send stake
                                            </template>
                                            <template v-else>
                                                Push stake
                                            </template>
                                        </template>

                                        <template v-else-if="action.type === 'pool:update_validator_set_hash'">
                                            Update validator
                                        </template>

                                        <template v-else-if="action.type === 'pool:process_withdraw_requests'">
                                            Process withdraws
                                        </template>
                                    </template>

                                    <!-- jetton actions: -->
                                    <template
                                        v-else-if="[
                                            'jetton:burn',
                                            'jetton:transfer',
                                            'jetton:transfer_notification',
                                            'jetton:internal_transfer',
                                            'jetton:swap',
                                            'jetton:route_tonapi',
                                        ].includes(action.type)">

                                        <span
                                            v-if="action.type === 'jetton:burn'">
                                            {{$t('events.jetton_burn')}}
                                        </span>

                                        <div v-else-if="action.type === 'jetton:swap'" class="mobile-swap mobile-swap--green">
                                            <span v-if="meta.jetton_out_address !== null" class="ui-inline-jetton__link ui-inline-jetton--green" v-bind:to="{ name: 'jetton', params: { address: meta.jetton_out_address }}">
                                                <ui-inline-jetton
                                                    v-bind:isOut="false"
                                                    v-bind:address="meta.jetton_out_address"
                                                    v-bind:value="meta.amount_out"
                                                    v-bind:symbol="meta.symbol_out || meta.jetton_out_address"
                                                    v-bind:decimals="meta.decimals_out"
                                                    show-sign
                                                />
                                            </span>
                                            <span style="color: var(--green-bright)" v-else>
                                                &plus;{{$ton(meta.amount_out, meta.decimals_out, true)}} TON
                                            </span>
                                        </div>

                                        <span
                                            v-else-if="action.type === 'jetton:transfer'">
                                            {{$t('events.transferred')}}
                                        </span>

                                        <template v-else>
                                            <span v-if="isOut">
                                                {{$t('events.transferred')}}
                                            </span>
                                            <span v-else>
                                                {{$t('events.received')}}
                                            </span>
                                        </template>
                                    </template>
                                </template>

                                <!-- other actions: -->
                                <template
                                    v-else-if="[
                                        'subscribe',
                                        'stake_deposit',
                                        'election_recover',
                                        'election_deposit',
                                        'call_contract',
                                        'stake_withdrawal',
                                        'jetton_mint',
                                        'auction_bid',
                                        'jetton_route',
                                        'dex_withdraw_liquidity',
                                        'dex_deposit_liquidity',
                                        'renew_dns',
                                        'change_dns',
                                        'delete_dns'
                                    ].includes(event) ||
                                        event === 'contract_deploy' && amount
                                    ">
                                    {{$t(`events.${event}`)}}
                                </template>

                                <template v-else-if="showFiatValue">
                                    <span style="margin-left: 10px;">≈ <ui-fiat v-bind:tonValue="amount"/></span>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div v-bind:class="isOut ? 'big-nft-container' : ''">
                        <ui-inline-nft-item
                            v-if="action && action?.type === 'nft:transfer_action'"
                            v-bind:address="action.nft"
                            v-bind:event-type="event"
                            v-bind:image-src="meta.image"
                            v-bind:nft-name="meta.name"
                            v-bind:is-out="isOut"
                            v-bind:collection-name="meta.collection"
                        />
                    </div>

                    <div v-if="message || encrypted || op === '0x2167da4b'" class="tx-mobile-content__bottom">
                        <!-- Encrypted message: -->
                        <template v-if="encrypted || op === '0x2167da4b'">
                            <div class="tx-mobile-comment">
                                <span class="tx-mobile-comment__contents muted" v-text="$t('tx.encrypted_message')"/>
                            </div>
                        </template>

                        <!-- Transaction comment: -->
                        <template v-else-if="message">
                            <div class="tx-mobile-comment" v-on:click="showMore">
                                <span class="tx-mobile-comment__contents"
                                    v-bind:class="{ 'tx-mobile-comment__contents--truncated': isTruncated }"
                                    v-text="message"/>

                                <button class="tx-mobile-comment__expander"
                                    v-if="isTruncated"
                                    v-text="$t('address.tx_table.show_more')"/>
                            </div>
                        </template>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { isSameAddress } from '~/tonweb.js';
import IconTxError from '@img/icons/tonscan/transactions/tx-failed.svg?vue';
import IconTxBounced from '@img/icons/tonscan/transactions/tx-excess.svg?vue';
import IconIn from '@img/icons/tonscan/transactions/tx-in.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconTxPending from '@img/icons/tonscan/round-loader.svg?vue';
import { truncateTicker } from '~/helpers';
import UiInlineJetton from '~/components/address/TxRowInlineJetton.vue';
import UiInlineNftItem from './TxRowInlineNft.vue';
import ActionIconMobile from './TxRowActionIconMobile.vue';
import ActionBadgeMobile from './TxRowActionBadgeMobile.vue';

export default {
    props: {
        address: String,
        date: String,
        from: String,
        is_service: Boolean,
        is_external: Boolean,
        is_aggregated: Boolean,
        is_success: Boolean,
        is_bounced: Boolean,
        is_swapped: Boolean,
        pending: Boolean,
        outputCount: Number,
        to: String,
        amount: String,
        message: String,
        timestamp: Number,
        txHash: String,
        exitCode: Number,
        op: [Number, String],
        source_type: String,
        source_alias: String,
        destination_type: String,
        destination_alias: String,
        action: Object,
        event: String,
        source: String,

        meta: {
            type: Object,
            default: () => ({}),
        },

        messageMaxChars: {
            type: Number,
            default: 200,
        },
        hideIcon: Boolean,
        encrypted: Boolean,
    },

    data() {
        return {
            isTruncated: false,
        };
    },

    computed: {
        showFiatValue() {
            return !this.action
                && !this.is_service
                && !this.is_external
                && !this.isLog
                && this.amount > 0
                && this.event !== 'withdraw_stake_request';
        },

        sender() {
            switch (this.action?.type) {
                case 'jetton:transfer_notification': {
                    return this.action.sender;
                }

                case 'nft:ownership_assigned': {
                    return this.action.prev_owner;
                }

                default: {
                    return this.from;
                }
            }
        },

        receiver() {
            switch (this.action?.type) {
                case 'jetton:transfer': {
                    return this.action.destination;
                }

                case 'nft:transfer': {
                    return this.action.new_owner;
                }

                default: {
                    return this.to;
                }
            }
        },

        isLog() {
            return !this.to;
        },

        isOut() {
            return isSameAddress(this.from, this.address);
        },

        transactionRoute() {
            return {
                name: 'tx',
                params: {
                    hash: this.txHash,
                },
            };
        },

        unsuccessfulBid() {
            return !this.is_success && this.event === 'auction_bid';
        },
    },

    created() {
        this.isTruncated = this.message && (this.message.length > this.messageMaxChars);
    },

    methods: {
        truncateTicker,
        showMore() {
            this.isTruncated = false;
        },

        truncateDomain(domain) {
            if (domain && domain.length > 20) {
                return domain.slice(0, 8) + '...' + domain.slice(-8);
            }
            return domain;
        },
    },

    components: {
        UiInlineNftItem,
        UiInlineJetton,
        IconTxError,
        IconTxBounced,
        IconTxPending,
        IconIn,
        IconOut,
        ActionIconMobile,
        ActionBadgeMobile,
    },
};
</script>
