<script>
import $bus from '~/eventBus.js';
import { cancel, format } from 'timeago.js';

const fullToShortMonthNames = {
    January: 'Jan',
    February: 'Feb',
    March: 'Mar',
    April: 'Apr',
    May: 'May',
    June: 'Jun',
    July: 'Jul',
    August: 'Aug',
    September: 'Sep',
    October: 'Oct',
    November: 'Nov',
    December: 'Dec',
    январь: 'янв',
    февраль: 'фев',
    март: 'мар',
    апрель: 'апр',
    май: 'май',
    июнь: 'июн',
    июль: 'июл',
    август: 'авг',
    сентябрь: 'сен',
    октябрь: 'окт',
    ноябрь: 'ноя',
    декабрь: 'дек',
};

export default {
    render(h) {
        const attrs = {
            datetime: this.timestamp,
        };

        return h('span', { attrs }, this.timeAgoText);
    },

    props: {
        timestamp: {
            required: true,
            type: Number,
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            timeFormat: this.$store.state.timeFormatData, // relative, smart и absolute switch
            timeAgoText: '',
        };
    },

    computed: {
        shouldAutoUpdate() {
            return this.timeFormat !== 'unix' && this.timeFormat !== 'absolute' && (Date.now() - this.timestamp) < 86400000;
            // return this.timeFormat !== 'unix' && (Date.now() - this.timestamp) < 86400000;
        },
    },

    methods: {
        // eslint-disable-next-line consistent-return
        updateTimeAgo() {
            const now = Date.now();
            const locale = this.$i18n.locale || 'en';

            if (!this.fixed) {
                switch (this.timeFormat) {
                    case 'smart':
                        this.timeAgoText = (now - this.timestamp) < 86400000
                            ? format(this.timestamp, locale)
                            : this.formatDate(this.timestamp);
                        break;
                    case 'absolute':
                        this.timeAgoText = this.formatDate(this.timestamp); // absolute
                        break;
                    case 'unix':
                        this.timeAgoText = this.timestamp;
                        break;
                    case 'relative':
                    default:
                        this.timeAgoText = format(this.timestamp, locale);
                        break;
                }
            } else {
                return format(this.timestamp, locale);
            }
            this.$forceUpdate();
        },

        formatDate(timestamp) {
            const date = new Date(timestamp);
            const locale = this.$i18n.locale || 'en';

            const year = date.getFullYear();
            const day = date.getDate();
            const fullMonthName = date.toLocaleString(locale, { month: 'long' });

            // Выполняем замену полного названия месяца на сокращенное
            const shortMonthName = fullToShortMonthNames[fullMonthName] || fullMonthName;

            const hours = date.getHours();
            const minutes = date.getMinutes().toString().padStart(2, '0');

            const currentYear = new Date().getFullYear();
            const formattedDate = year !== currentYear ? `${day} ${shortMonthName} ${year}` : `${day} ${shortMonthName}`;

            return `${formattedDate}, ${hours}:${minutes}`;
        },

        getDateOptions(year) {
            const currentYear = new Date().getFullYear();
            return currentYear === year
                ? { day: 'numeric', month: 'long' }
                : { year: 'numeric', month: 'long', day: 'numeric' };
        },
    },

    mounted() {
        this.updateTimeAgo();
        if (this.shouldAutoUpdate) {
            $bus.$on('updateTimeAgo', this.updateTimeAgo);
        }
    },

    beforeDestroy() {
        if (this.shouldAutoUpdate) {
            cancel(this.$el);
            $bus.$off('updateTimeAgo', this.updateTimeAgo);
        }
    },

    watch: {
        '$i18n.locale': function () {
            this.updateTimeAgo();
        },

        timestamp() {
            this.updateTimeAgo();
        },

        '$store.state.timeFormatData': function (newVal) {
            this.timeFormat = newVal;

            this.updateTimeAgo();
        },
    },

    updated() {
        if (!this.shouldAutoUpdate) {
            this.$el.textContent = this.timeAgoText;
        }
    },
};
</script>
