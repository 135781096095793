<template>
    <ui-link
        class="user-nft"
        v-bind:to="{
            name: 'nft',
            params: {
                address: address,
                skeletonHint: 'item',
            },
        }">
        <div class="user-nft__image">
            <template v-if="getImgProxy">
                <div v-show="!imageLoaded" style="border-radius: 0" class="skeleton"/>

                <img class="user-nft__image__img" decoding="async"
                    v-if="!imageError"
                    v-bind:src="getImgProxy"
                    v-bind:loading="'lazy'"
                    v-bind:alt="content?.name"
                    v-on:load="imageLoaded = true"
                    v-on:error="imageError = true; imageLoaded = true">

                <div class="user-nft__image__broken" v-else>
                    <icon-broken />
                </div>
            </template>
            <div class="user-nft__image__broken" v-else>
                <icon-broken />
            </div>
        </div>

        <footer v-once class="user-nft__footer">
            <ui-link class="user-nft__collection"
                v-if="collection?.name && collectionAddress"
                v-bind:to="{
                    name: 'collection',
                    params: {
                        address: collectionAddress,
                        skeletonHint: 'collection',
                    },
                }">
                <span class="user-nft__collection__text" v-text="collection.name"/>
                <!--<icon-verified class="user-nft__collection__icon" v-if="item.collection.isVerified" viewBox="0 0 16 16"/>-->
            </ui-link>

            <span v-else class="user-nft__collection" v-text="$t('address.nfts.no_collection')"/>

            <h3 class="user-nft__name" v-if="itemName" v-text="itemName"/>
            <h3 class="user-nft__name muted" v-else v-text="$t('address.nfts.no_name')"/>
        </footer>
    </ui-link>
</template>

<script>
import IconBroken from '@img/icons/tonscan/image-broken.svg?vue';
// import IconVerified from '@primer/octicons/build/svg/verified-16.svg?vue';

export default {
    props: {
        idx: Number,
        address: String,
        content: Object,
        collection: Object,
        collectionAddress: String,
    },

    data() {
        return {
            imageError: false,
            imageLoaded: false,
        };
    },

    computed: {
        itemName() {
            return this.content?.name || this.content?.domain || this.content?.extra?.domain;
        },
        getImgProxy() {
            if (this.collectionAddress === 'EQC3dNlesgVD8YbAazcauIrXBPfiVhMMr5YYk2in0Mtsz0Bz') {
                return `https://dns-image.mytonwallet.org/img?d=${this.itemName.replace('.ton', '')}`;
            }

            const proxyUrl = this.content?.extra?._image_big; // eslint-disable-line no-underscore-dangle
            const fallbackUrl = this.content?.image;

            return proxyUrl || fallbackUrl;
        },
    },

    components: {
        IconBroken,
        // IconVerified,
    },
};
</script>
