import { render, staticRenderFns } from "./PageSuspended.vue?vue&type=template&id=2a9bf1f3"
import script from "./PageSuspended.vue?vue&type=script&lang=js"
export * from "./PageSuspended.vue?vue&type=script&lang=js"
import style0 from "./PageSuspended.vue?vue&type=style&index=0&id=2a9bf1f3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports