<template>
    <span class="ui-inline-jetton">
        <span class="ui-inline-jetton__value">
            {{$ton(meta.amount_in, meta.decimals_in, isMobile || round)}}
        </span>
        <ui-link v-if="meta.jetton_in_address !== null" class="ui-inline-jetton__link tx-table-jetton-link-standart" v-bind:to="{ name: 'jetton', params: { address: meta.jetton_in_address }}">
            {{ truncateTicker(meta.symbol_in) || shortenAddress(meta.jetton_in_address) }}
        </ui-link>
        <span v-else>
            TON
        </span>
        <IconArrowRight class="swap-icon-arrow" style="margin: 0 2px;"/>
        <span class="ui-inline-jetton__value ui-inline-jetton--green">
            {{$ton(meta.amount_out, meta.decimals_out, isMobile || round)}}
        </span>
        <ui-link v-if="meta.jetton_out_address !== null" class="ui-inline-jetton__link ui-inline-jetton--green" v-bind:to="{ name: 'jetton', params: { address: meta.jetton_out_address }}">
            {{ truncateTicker(meta.symbol_out) || shortenAddress(meta.jetton_out_address) }}
        </ui-link>
        <span style="color: var(--green-bright)" v-else>
            TON
        </span>
    </span>
</template>

<script>
import IconArrowRight from '@img/icons/tonscan/arrow-small-right.svg?inline';
import { truncateTicker } from '../../helpers';

export default {
    props: {
        meta: {
            type: Object,
        },
        round: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    methods: {
        truncateTicker,
        shortenAddress(address) {
            return address.slice(0, 3) + '...' + address.slice(-3);
        },
    },
    components: {
        IconArrowRight,
    },
};
</script>

<style lang="scss">
.swap-icon-arrow {
    opacity: .7;
    width: 8px;
    height: 10px;
}
</style>
