<template>
    <section>
        <section class="nft-item-container">
            <aside class="nft-image-container" v-bind:class="{ 'nft-image-container__dns': contractType !== 'nft' }">
                <section v-if="imageError" class="nft-image-container__image">
                    <div class="nft-item-image-error" v-on:click="reloadImage">
                        <icon-broken-image class="nft-item-image-error__icon"/>

                        <h3 class="nft-item-image-error__title" v-text="$t('nft.error.error_image')"/>

                        <div class="nft-item-image-error__text nft-item-image-error__text--desktop"
                            v-text="$t('nft.error.retry_pc')"/>

                        <div class="nft-item-image-error__text nft-item-image-error__text--mobile"
                            v-text="$t('nft.error.retry_phone')"/>
                    </div>
                </section>

                <template v-else>
                    <div v-show="!imageLoaded" class="nft-image-container__image skeleton"/>

                    <!--<video v-show="video && imageLoaded"-->
                    <!--    class="nft-image-container__image"-->
                    <!--    v-bind:src="video?.video"-->
                    <!--    v-bind:poster="video?.preview"-->
                    <!--    v-on:canplay="imageLoaded = true"-->
                    <!--    v-on:error="imageLoaded = true"-->
                    <!--    muted-->
                    <!--    autoplay-->
                    <!--    loop-->
                    <!--/>-->

                    <div class="nft-image-container__lottie" ref="lottieContainer" v-show="lottie"></div>

                    <img class="nft-image-container__image"
                        v-show="!lottie && image && imageLoaded"
                        v-bind:key="imageKey"
                        v-bind:src="image"
                        v-on:error="imageError = true"
                        v-on:load="imageLoaded = true"
                    />
                </template>
            </aside>

            <section class="nft-item-container__info" v-bind:class="{ 'nft-item-container__info--dns': contractType !== 'nft' }">
                <header class="nft-item-header" v-if="contractType === 'nft'">
                    <h2 class="nft-item-header__title">
                        <span v-if="!name" class="skeleton skeleton--inline">Some cool NFTs 2599</span>
                        <span v-else v-text="name"/>
                    </h2>

                    <div class="nft-item-header__description">
                        <!-- External JSON is loaded, description is not set: -->
                        <span class="muted"
                            v-if="description === null || description === ''"
                            v-text="$t('common.no_description')"/>

                        <!-- Data is loading: -->
                        <span v-else-if="!description" class="skeleton skeleton--inline">Some cool NFTs pretty long description text</span>

                        <!-- Everything is loaded, description is set: -->
                        <template v-else>{{description}}</template>
                    </div>
                </header>

                <div class="card">
                    <div class="card-row" v-if="contractType !== 'nft'">
                        <div class="card-row__name" v-text="$t(`nft.item.type.${contractType}`)"/>
                        <div class="card-row__value" v-if="name">
                            <span class="card-domain-container">
                                <ui-copy-button v-bind:copy="name">
                                    {{ name }}
                                </ui-copy-button>
                                <a v-if="dnsLink" v-bind:href="dnsLink" target="_blank" rel="nofollow">
                                    <icon-domain class="card-domain-icon card-main-qr-button" />
                                </a>
                            </span>
                        </div>
                        <div v-else class="card-row__value">
                            <span class="skeleton">00000 TON ≈ 00000 USD</span>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('nft.item.collection')"/>
                        <div class="card-row__value" style="min-width: 200px;">
                            <!-- null means that all data is loaded, but the item is standalone (no collection): -->
                            <span v-if="collectionAddress === null" class="muted" v-text="$t('nft.item.no_collection')"/>

                            <!-- this case is for when there's no metadata of collection, but we have collection's address -->
                            <template v-else-if="collectionAddress && collectionName === undefined">
                                <ui-address v-bind:address="collectionAddress" />
                            </template>

                            <!-- if either of item info or collection info is loading, show skeleton: -->
                            <template v-else-if="collectionAddress === undefined || collectionName === undefined">
                                <span class="nft-card-collection__name skeleton">Some Address</span>
                            </template>

                            <ui-link v-else class="nft-card-collection" v-bind:to="collectionRouterParams">
                                <ui-round-image size="tiny" class="nft-card-collection__thumb"
                                    v-if="collectionName"
                                    v-bind:src="collectionThumb"/>

                                <span class="nft-card-collection__name" v-text="collectionName || collectionAddress"/>
                            </ui-link>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('nft.item.owner')"/>
                        <div class="card-row__value" style="min-width: 200px;">
                            <span v-if="ownerAddress === undefined" class="skeleton">Some other Address</span>
                            <span v-else-if="ownerAddress === null" class="muted">{{ $t('nft.item.no_owner') }}</span>
                            <ui-address v-else v-bind:address="ownerAddress" v-bind:alias="ownerAlias"/>
                        </div>
                    </div>

                    <div class="card-row" v-if="contractType !== 'nft'">
                        <div class="card-row__name" v-text="$t('nft.item.address')"/>
                        <div class="card-row__value">
                            <ui-copy-button class="card-main-address"
                                v-bind:successMessage="$t('address.info.copy_success')"
                                v-bind:copy="address"
                            >
                                {{address}}
                            </ui-copy-button>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('address.info.balance')"/>
                        <div class="card-row__value" v-if="balance == '0' || balance">
                            {{$ton(balance)}} TON

                            <template v-if="balance != '0'">
                                <span v-if="$store.state.exchangeRate" style="color: #717579">
                                    ≈ <ui-fiat v-bind:tonValue="balance"/>
                                </span>
                            </template>
                        </div>
                        <div v-else class="card-row__value">
                            <span class="skeleton">00000 TON ≈ 00000 USD</span>
                        </div>
                    </div>
                    <div class="card-row" v-if="showLoadNext">
                        <div class="card-row__name" v-text="$t('nft.item.number')"/>
                        <div class="card-row__value" style="display: flex;">
                            <span v-if="!index" class="skeleton">6969</span>
                            <span v-else v-text="index"/>

                            <template v-if="collectionItemCount">
                                &nbsp;/&nbsp;{{collectionItemCount}}
                            </template>

                            <nav class="card-row-inline-pager" role="navigation">
                                <span class="card-row-inline-pager__arrow" tabindex="0"
                                    v-on:click="loadNext(index - 1)" v-on:keyup.enter="loadNext(index - 1)"
                                    v-bind:disabled="!address || index <= 1">
                                    <icon-prev class="card-row-inline-pager__arrow__svg"/>
                                </span>

                                <span class="card-row-inline-pager__arrow" tabindex="0"
                                    v-on:click="loadNext(index + 1)" v-on:keyup.enter="loadNext(index + 1)"
                                    v-bind:disabled="!address || index >= collectionItemCount">
                                    <icon-next class="card-row-inline-pager__arrow__svg"/>
                                </span>
                            </nav>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('nft.item.metadata_source')"/>
                        <div class="card-row__value">
                            <ui-metadata-source v-bind:url="metadataUrl" v-bind:loader="() => getRawMetadata"/>
                        </div>
                    </div>

                    <div class="card-row" v-if="contractType !== 'nft'">
                        <div class="card-row__name" v-text="$t('address.info.details')"/>
                        <div class="card-row__value">
                            <div class="card-row__value-tags">
                                <div class="card-row__value-tag-item"
                                    v-on:mouseover="showTooltip.state = true"
                                    v-on:mouseleave="showTooltip.state = false">
                                    <div class="card-row__value-tag card-row__value-tag-green">{{ $t('address.info.type_active') }}</div>
                                    <ui-tooltip
                                        v-bind:value="$t('address.info.state')"
                                        position="top"
                                        v-if="showTooltip.state"
                                    />
                                </div>
                                <div class="card-row__value-tag-item"
                                    v-on:mouseover="showTooltip.contract = true"
                                    v-on:mouseleave="showTooltip.contract = false">
                                    <div class="card-row__value-tag card-row__value-tag-gray">
                                        {{ $t(`address.contract_type.${this.contractType}`) }}
                                    </div>
                                    <ui-tooltip
                                        v-bind:value="$t('address.info.contract_type')"
                                        position="top"
                                        v-if="showTooltip.contract"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-row" v-if="contractType === 'nft'">
                        <div class="card-row__name" v-text="$t('nft.item.attributes')"/>
                        <div v-if="attributes && attributes.length > 0" class="card-row__value card-row__value--flex-row">
                            <div class="card-row"
                                v-for="attribute in attributes"
                                v-bind:key="`nft_attribute_${address}_${attribute.trait_type}`">
                                <div class="card-row__name" v-text="attribute.trait_type"/>
                                <div class="card-row__value">
                                    <span v-if="attribute.value === undefined" class="skeleton">some value</span>
                                    <template v-else>
                                        {{attribute.value}}
                                    </template>
                                </div>
                            </div>
                        </div>
                        <span v-else class="muted" v-text="$t('nft.item.no_attributes')"/>
                    </div>
                </div>
            </section>
        </section>

        <nft-tabs
            v-bind:address="address"
        />
    </section>
</template>

<script>
import { getNftItemByCollectionIndex } from '~/api';
import IconPrev from '@primer/octicons/build/svg/chevron-left-16.svg?inline';
import IconNext from '@primer/octicons/build/svg/chevron-right-16.svg?inline';
import IconBrokenImage from '@img/icons/tonscan/image-broken.svg?inline';
import UiMetadataSource from '~/components/UiMetadataSource.vue';
import { getAccountStates, getNftByAddress } from '~/api/toncenterV3';
import lottie from 'lottie-web';
import { showToast } from '~/toast';
import IconDomain from '@img/icons/tonscan/domain_renew.svg?vue';
import NftTabs from './NftTabs.vue';
import contractHashes from '../../../json/contractHashes.json';

export default {
    animationError: undefined,
    props: {
        address: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            collectionAddress: undefined,
            ownerAddress: undefined,
            ownerAlias: undefined,
            image: null,
            video: undefined,
            lottie: undefined,
            name: undefined,
            description: undefined,
            index: undefined,
            attributes: [],
            marketplace: undefined,
            externalUrl: undefined,
            metadataUrl: undefined,
            propertiesVisible: false,
            collectionName: undefined,
            collectionThumb: undefined,
            collectionItemCount: undefined,
            imageKey: 1,
            imageError: false,
            imageLoaded: false,
            balance: undefined,
            contractType: 'nft',
            showTooltip: {
                state: false,
                contract: false,
            },
        };
    },

    computed: {
        collectionRouterParams() {
            return this.$localizeRoute({
                name: 'collection',
                params: {
                    address: this.collectionAddress,
                },
            });
        },
        getRawMetadata() {
            return {
                address: this.address,
                collectionAddress: this.collectionAddress,
                ownerAddress: this.ownerAddress,
                image: this.image,
                name: this.name,
                description: this.description,
                index: this.index,
                attributes: this.attributes,
                externalUrl: this.externalUrl,
                metadataUrl: this.metadataUrl,
            };
        },
        showLoadNext() {
            return this.collectionAddress !== null
                && this.collectionItemCount
                && this.collectionItemCount > 0
                && this.index < this.collectionItemCount;
        },
        dnsLink() {
            if (!this.name) return null;

            switch (this.contractType) {
                case 'dns_domain':
                    return `http://${this.name}`;
                case 'telegram_number':
                    return `https://t.me/${this.name.replaceAll(' ', '')}`;
                case 'telegram_username':
                    return `https://${this.name.substring(1)}.t.me/`;
                default:
                    return null;
            }
        },
    },

    watch: {
        address: {
            immediate: true,
            handler() {
                this.checkType();
            },
        },
    },

    methods: {
        reset() {
            this.collectionAddress = undefined;
            this.ownerAddress = undefined;
            this.ownerAlias = undefined;
            this.image = null;
            this.lottie = undefined;
            this.video = undefined;
            this.name = undefined;
            this.description = undefined;
            this.index = undefined;
            this.attributes = [];
            this.marketplace = undefined;
            this.externalUrl = undefined;
            this.metadataUrl = undefined;
            this.propertiesVisible = false;
            this.collectionName = undefined;
            this.collectionThumb = undefined;
            this.collectionItemCount = undefined;
            this.imageKey = 1;
            this.imageError = false;
            this.imageLoaded = false;
            this.balance = undefined;
            this.contractType = 'nft';
            this.$options.animationError = undefined;
        },

        async checkType() {
            const response = await getAccountStates(this.address);
            const metadata = response?.metadata[response?.accounts[0]?.address]?.token_info[0];
            const metadataType = metadata?.type;
            this.balance = response?.accounts[0]?.balance;

            switch (metadataType) {
                case 'nft_items': {
                    this.metadataUrl = metadata?.extra?.uri || null;
                    await this.loadNftData();
                    break;
                }

                case 'nft_collections': {
                    await this.$router.replace(this.$localizeRoute({
                        name: 'collection',
                        params: { address: this.address },
                    }));
                    break;
                }

                default: {
                    showToast(this.$t('nft.error.redirect_invalid'));

                    await this.$router.replace(this.$localizeRoute({
                        name: 'address',
                        params: { address: this.address },
                    }));
                }
            }
            this.contractType = contractHashes[response?.accounts[0]?.code_hash] || 'nft';
        },

        /* eslint no-underscore-dangle: "off" */
        convertApiResponseToNftItemProps(r) {
            this.collectionAddress = r.address_book[r?.nft_items[0]?.collection_address]?.user_friendly || null;
            this.collectionName = r.metadata[r?.nft_items[0]?.collection_address]?.token_info[0]?.name;
            this.collectionThumb = r.metadata[r?.nft_items[0]?.collection_address]?.token_info[0]?.extra?._image_big;
            // this.collectionItemCount = r?.collection?.itemsCount;
            this.ownerAddress = r.address_book[r?.nft_items[0]?.owner_address]?.user_friendly || null;
            this.ownerAlias = r.address_book[r?.nft_items[0]?.owner_address]?.domain;
            this.name = r.metadata[r?.nft_items[0]?.address]?.token_info[0]?.name || r.metadata[r?.nft_items[0]?.address]?.token_info[0]?.extra?.domain;
            this.index = r?.nft_items[0]?.index;
            this.description = r.metadata[r?.nft_items[0]?.address]?.token_info[0]?.description || null;
            this.externalUrl = r?.nft_items[0]?.content?.uri;
            this.attributes = r.metadata[r?.nft_items[0]?.address]?.token_info[0]?.extra?.attributes || [];
            this.image = r.metadata[r?.nft_items[0]?.address]?.token_info[0]?.extra?._image_big;
            this.lottie = r.metadata[r?.nft_items[0]?.address]?.token_info[0]?.extra?.lottie;

            // Hardcode DNS Domains images
            if (this.collectionAddress === 'EQC3dNlesgVD8YbAazcauIrXBPfiVhMMr5YYk2in0Mtsz0Bz' && this.name) {
                this.image = `https://dns-image.mytonwallet.org/img?d=${this.name.replace('.ton', '')}`;
            }
        },

        async loadNftData() {
            const response = await getNftByAddress(this.address);
            this.convertApiResponseToNftItemProps(response);

            if (this.lottie) {
                this.$options.animationError = lottie.loadAnimation({
                    container: this.$refs.lottieContainer,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    path: this.lottie,
                });
                // This event listener will be triggered if lottie animation is unreachable (CORS or 404 for example)
                this.$options.animationError.addEventListener('data_failed', () => {
                    this.lottie = false;
                });
                this.imageLoaded = true;
            }
        },

        reloadImage() {
            this.imageError = false;
            this.imageKey += 1;
        },

        async loadNext(index) {
            const collectionAddress = this.collectionAddress;
            this.reset();

            const response = await getNftItemByCollectionIndex(collectionAddress, index);

            this.$router.push(this.$localizeRoute({
                name: 'nft',
                params: {
                    address: response?.item_address,
                },
            }));
        },
    },

    beforeDestroy() {
        if (this.$options.animationError) {
            this.$options.animationError.removeEventListener('data_failed', () => {
                this.lottie = false;
            });
            this.$options.animationError = undefined;
        }
    },

    components: {
        UiMetadataSource,
        // NftImageAnimation,
        IconPrev,
        IconNext,
        NftTabs,
        IconBrokenImage,
        IconDomain,
    },
};
</script>

<style lang="scss">
.nft-item-container {
    display: flex;
    margin-top: 32px;
    flex-direction: row;
    margin-bottom: 32px;
    &__info {
        margin-top: auto;
        flex-grow: 1;
        flex-basis: 100%;

        &--dns {
            margin-top: 0;
        }
    }
}

.nft-item-transactions-container {
    margin-top: 32px;
}

.nft-item-image-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 130px;
    padding-bottom: 130px;
    width: 100%;
    height: 100%;
    &__icon {
        width: 36px;
        cursor: pointer;
        fill: currentColor;
        margin-bottom: 24px;
        margin-top: -16px;
    }
    &__title {
        padding: 0;
        text-align: center;
        margin: 0 0 8px;
        font-weight: 500;
        font-size: 18px;
    }
    &__text {
        opacity: .5;
        cursor: pointer;
        font-size: 0.85em;
        &--mobile {
            display: none;
        }
    }

    @media all and (max-width: 480px) {
        &__icon {
            margin-bottom: 16px;
        }
        &__title {
            font-size: 16px;
        }
        &__text {
            &--mobile {
                display: block;
            }
            &--desktop {
                display: none;
            }
        }
    }
}

.nft-image-container {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-right: 32px;
    background-size: cover;
    background-position: center;
    background-blend-mode: soft-light;
    border: 1px solid transparent;
    width: 100%;
    max-width: 420px;
    //min-height: 260px;
    position: relative;

    &__dns {
        max-width: 327px;
    }

    &__lottie {
        width: 100%;
        height: auto;
        border-radius: 16px!important;
        overflow: hidden;
        aspect-ratio: 1 / 1;
    }

    &__image {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 16px!important;
        display: block;
        object-fit: contain;
        overflow: hidden;
        //margin: auto;

        &.skeleton {
            border-radius: 16px;
            background-image: none;
            box-shadow: none;
            aspect-ratio: 1 / 1;
            width: 100%;
        }
    }
}

.nft-item-header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 150px;
    &__title {
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 12px;
        margin-top: 12px;
    }
    &__description {
        font-size: 17px;
        margin-bottom: 18px;
        line-height: 1.4;
    }
}

.nft-card-collection {
    display: flex;
    align-items: center;
    &__thumb {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin: -8px 6px -8px 0;
    }
}

.card-row-inline-pager {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        margin: -6px;
        cursor: pointer;
        &[disabled] {
            color: #777;
            pointer-events: none;
            opacity: .5;
        }
        &:hover .card-row-inline-pager__arrow__svg {
            opacity: 1;
        }
        &__svg {
            cursor: pointer;
            fill: currentColor;
            width: 12px;
            height: 12px;
            padding: 4px;
            margin: -4px 2px;
            background: var(--card-row-separator);
            opacity: .6;
            transition: .08s opacity ease-out;
            border-radius: 4px;
        }
    }
}

@media all and (max-width: 800px) {
    .nft-item-container {
        flex-direction: column;
        margin-top: 8px;
        margin-bottom: 18px;
    }

    .nft-image-container {
        max-width: unset;
        overflow: hidden;
        border-radius: 16px;
        &__image {
            height: 100%;
            border-radius: 16px;
        }

        &__dns {
            margin-bottom: 16px;
        }
    }

    .nft-item-transactions-container {
        margin-top: 18px;
    }

    .nft-item-header {
        box-sizing: border-box;
        padding: 0 16px;
        &__title {
            margin-top: 18px;
            margin-bottom: 6px;
            font-size: 26px;
        }
        &__description {
            font-size: 16px;
            line-height: 1.4;
        }
    }

    .nft-card-collection__thumb {
        width: 20px;
        height: 20px;
    }

    .card-row-inline-pager {
        &__arrow {
            &__svg {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }
}
</style>
