<template>
    <section>
        <div class="block-info-section">
            <div
                class="card information-block"
                v-for="(item, idx) in informationBlocks"
                v-bind:key="`blocks_infoblock_${idx}`">
                <div class="chart-box">
                    <header class="chart-box__header" v-text="item.header"></header>
                    <div class="chart-box__value">
                        <template v-if="!item.value || item.value[0] == 0">
                            <span class="skeleton">1,234,567,890</span>
                        </template>
                        <template v-else-if="Number.isFinite(item.value)">
                            <ui-animated-number v-bind:value="item.value" v-bind:suffix="item.suffix"/>
                        </template>
                        <template v-else>
                            {{ item.value }}
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <workchain-blocks v-if="isWorkchainBlocksPage" v-bind:workchain="$route.query.wc" />

        <template v-else>
            <div class="card" style="margin-bottom: 18px;">
                <div class="card-title">{{ $t('address.all_tx_table.title') }}</div>
                <section class="block-recent-tx-section">
                    <transactions-table />
                </section>
            </div>

            <section class="block-chains-section">
                <div class="card">
                    <page-blocks-mini-table v-bind:blocks="masterchainBlocks" title="masterchain" />
                </div>
                <div class="card">
                    <page-blocks-mini-table v-bind:blocks="workchainBlocks" title="workchain" />
                </div>
            </section>
        </template>
    </section>
</template>

<script>
import { getBlockchainStats } from '~/api/extenderContracts.js';
import { getPreviousBlocks } from '~/api/toncenterV3.js';
import UiAnimatedNumber from '~/components/UiAnimatedNumber.vue';
import PageBlocksMiniTable from './PageBlocksMiniTable.vue';
import WorkchainBlocks from './PageBlocksWorkchainBlockTable.vue';
import TransactionsTable from './PageBlocksTransactions.vue';

export default {
    data() {
        return {
            blockAnal: null,
            lastBlockTime: 0,
            blocks: [],
            isLoading: false,
            mcHeight: null,
            totalTx: null,
            averageTps: 0,
            timerHandle: null,
        };
    },

    computed: {
        isWorkchainBlocksPage() {
            return !Number.isNaN(Number(this.$route.query.wc));
        },
        masterchainBlocks() {
            return this.blocks.filter(b => b.workchain === -1).slice(0, 15);
        },
        workchainBlocks() {
            return this.blocks.filter(b => b.workchain === 0).slice(0, 15);
        },
        shouldHandleScroll() {
            return !this.isLoading;
        },
        informationBlocks() {
            return [
                { header: this.$t('stats.masterchain_height'), value: this.mcHeight },
                { header: this.$t('blocks.block_time'), value: this.$tc('common.seconds', Number(this.lastBlockTime).toFixed(2)) },
                { header: this.$t('stats.transaction_count'), value: this.totalTx },
                { header: this.$t('blocks.tx_per_second'), value: `${this.averageTps.toFixed(2)} TPS` },
            ];
        },
    },

    async created() {
        this.initializeTotalTx();
        await Promise.all([this.loadPreviousBlocks(), this.blockAnalytics()]);
        this.timerHandle = setInterval(this.loadPreviousBlocks, 6000);
    },

    beforeDestroy() {
        clearInterval(this.timerHandle);
    },

    metaInfo() {
        return {
            title: this.$t('blocks.meta.title'),
        };
    },

    methods: {
        initializeTotalTx() {
            try {
                this.totalTx = JSON.parse(localStorage.getItem('totalTx')) || null;
            } catch (error) {
                console.error('Failed to parse totalTx from localStorage', error);
                this.totalTx = null;
            }
        },

        async loadPreviousBlocks() {
            try {
                this.isLoading = true;
                const blocks = await getPreviousBlocks({ limit: 80 });
                if (!blocks.length) return;

                this.blocks = blocks;
                this.updateBlockMetrics(blocks);
            } catch (error) {
                console.error('Error loading previous blocks:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async updateBlockMetrics() {
            // // this.mcHeight = formatter.format(blocks[0].seqno);
            // // this.lastBlockTime = blocks[0].gen_utime - blocks[1].gen_utime;
            // // this.averageTps = blocks.reduce((acc, { tx_count }) => acc + tx_count, 0) / blocks.length;

            // if (Number.isInteger(this.totalTx)) {
            //     const masterchainTx = this.masterchainBlocks[0]?.tx_count || 0;
            //     const workchainTx = this.workchainBlocks[0]?.tx_count || 0;
            //     this.totalTx += masterchainTx + workchainTx;
            // }

            // try {
            //     const resAnal = await getBlockchainStats();
            //     console.log('aaaaaa', resAnal)
            //     this.mcHeight = formatter.format(resAnal.latest_masterchain_seqno);
            //     // this.lastBlockTime = resAnal.stats_1m.average_block_time;
            //     // this.averageTps = resAnal.stats_1m.average_tps;

            // } catch (e) {
            //     console.log('error', e)
            // }

            // localStorage.setItem('totalTx', JSON.stringify(this.totalTx));

            try {
                const resAnal = await getBlockchainStats();

                this.mcHeight = this.$round(resAnal.latest_masterchain_seqno);
                this.lastBlockTime = resAnal.stats_1m.average_block_time;
                this.averageTps = parseFloat(resAnal.stats_1m.average_tps);
            } catch (error) {
                console.error(error);
            }
        },

        async blockAnalytics() {
            try {
                // const analytics = await blockAnal();
                const resAnal = await getBlockchainStats();

                // this.blockAnal = analytics;
                this.mcHeight = this.$round(resAnal.latest_masterchain_seqno);
                this.totalTx = this.$round(resAnal.trans_ord_count);
                this.lastBlockTime = resAnal.stats_1m.average_block_time;
                this.averageTps = parseFloat(resAnal.stats_1m.average_tps);

                localStorage.setItem('totalTx', JSON.stringify(this.totalTx));
            } catch (error) {
                console.error('Error fetching block analytics:', error);
            }
        },
    },

    components: {
        UiAnimatedNumber,
        PageBlocksMiniTable,
        WorkchainBlocks,
        TransactionsTable,
    },
};
</script>

<style lang="scss">
.block-info-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 82px;
    grid-gap: 14px;
    margin-bottom: 18px;
    .information-block {
        margin-top: 0 !important;
    }
}

.block-recent-tx-section {
    height: 356px;
    overflow: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        display: none;
    }
}

.block-chains-section {
    display: grid;
    width: 100%;
    align-items: stretch;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .card + .card {
        margin: 0;
    }
}

@media screen and (max-width: 900px) {
    .block-info-section {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-chains-section {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 599px) {
    .block-info-section {
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin-bottom: 14px;

        .information-block {
            border-radius: 0;
            border-bottom-width: 0;
            &:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
            &:last-child {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                border-bottom-width: 1px;
            }
        }

        .chart-box {
            &__value {
                font-size: 24px;
            }
        }
    }
}
</style>
