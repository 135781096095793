<template>
    <section>
        <section>
            <div class="card">
                <div class="card-title">
                    <span v-if="isMasterChain" v-text="$t('block.masterchain_block_title')"/>
                    <span v-else v-text="$t('block.shardchain_block_title')"/>
                </div>

                <div class="card-row card-row--wide">
                    <div class="card-row__name" v-text="$t('tx.workchain')"/>
                    <div class="card-row__value" v-text="workchain"/>
                </div>

                <div class="card-row card-row--wide">
                    <div class="card-row__name" v-text="$t('tx.shard')"/>
                    <div class="card-row__value" v-text="shard"/>
                </div>

                <div class="card-row card-row--wide">
                    <div class="card-row__name" v-text="$t('tx.seqno')"/>
                    <div class="card-row__value" v-text="seqno"/>
                </div>

                <div class="card-row card-row--wide">
                    <div class="card-row__name">LT</div>
                    <div class="card-row__value">
                        <span v-if="block.start_lt">{{block.start_lt}} ... {{block.end_lt}}</span>
                        <span v-else class="skeleton">00000000000000 ... 00000000000000</span>
                    </div>
                </div>

                <div class="card-row card-row--wide">
                    <div class="card-row__name" v-text="$t('block.generated_at')"/>
                    <div class="card-row__value">
                        <template v-if="block.gen_utime">
                            <ui-datetime v-bind:timestamp="block.gen_utime"/>
                            &nbsp;
                            <ui-timeago class="muted" v-bind:timestamp="block.gen_utime * 1000"/>
                        </template>
                        <span v-else class="skeleton">00000000000000</span>
                    </div>
                </div>

                <div class="card-row card-row--wide">
                    <div class="card-row__name">Root hash</div>
                    <div class="card-row__value">
                        <ui-copy-button v-if="block.root_hash" v-bind:copy="block.root_hash">
                            {{block.root_hash}}
                        </ui-copy-button>
                        <span v-else class="skeleton">zMLQwaoiatVZRJ0PY019KkbRoJYQjNS6azjpVNbMsOQ=</span>
                    </div>
                </div>

                <div class="card-row card-row--wide">
                    <div class="card-row__name">File hash</div>
                    <div class="card-row__value">
                        <ui-copy-button v-if="block.file_hash" v-bind:copy="block.file_hash">
                            {{block.file_hash}}
                        </ui-copy-button>
                        <span v-else class="skeleton">zMLQwaoiatVZRJ0PY019KkbRoJYQjNS6azjpVNbMsOQ=</span>
                    </div>
                </div>

                <div v-show="boringFieldsVisible">
                    <div class="card-row card-row--wide" v-for="field in boringFields" v-bind:key="`block_boring_${field}`">
                        <div class="card-row__name" v-text="field"/>
                        <div class="card-row__value" v-text="block[field]"/>
                    </div>
                </div>

                <div v-if="!boringFieldsVisible" class="card-row card-row--wide">
                    <div class="card-row__name">
                        <div class="block-boring-expand" v-on:click="boringFieldsVisible = true">
                            {{$t('block.show_details', [boringFields.length])}}
                            <icon-chevron-down fill="none"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block-nav">
                <div v-show="hasPrevBlocks" class="block-nav__vcontainer">
                    <ui-link class="block-nav__pill block-nav__pill--prev"
                        v-for="prev in block.prev_blocks"
                        v-bind:key="prev.file_hash"
                        v-bind:to="{ name: 'block', params: {
                            workchain: prev.workchain,
                            shard: prev.shard,
                            seqno: prev.seqno,
                        }}">
                        <icon-prev-block/>
                        {{$t('block.nav.prev')}}
                    </ui-link>
                </div>

                <div v-show="!hasPrevBlocks">
                    <div class="block-nav__pill block-nav__pill--prev block-nav__pill--disabled">
                        <icon-prev-block/>
                        {{$t('block.nav.prev')}}
                    </div>
                </div>

                <div v-if="isMasterChain" class="block-nav__hcontainer">
                    <ui-link class="block-nav__pill block-nav__pill--next" v-bind:to="{ name: 'block', params: nextBlockLinkParams }">
                        {{$t('block.nav.next')}}
                        <icon-next-block/>
                    </ui-link>

                    <span class="block-nav__pill block-nav__pill--next" v-on:click="goToLatestBlock">
                        {{$t('block.nav.latest')}}
                        <icon-latest-block/>
                    </span>
                </div>
            </div>

            <div v-if="isMasterChain" class="card">
                <div class="card-title" v-text="$t('block.shardchain_blocks_title')"/>
                <div class="tx-history-wrap">
                    <table class="ui-table">
                        <thead>
                            <tr>
                                <th v-pre width="5"></th>
                                <th style="text-align: center" width="80" v-text="$t('tx.workchain')"/>
                                <th v-text="$t('tx.shard')"/>
                                <th v-text="$t('tx.seqno')"/>
                            </tr>
                        </thead>

                        <tbody v-show="shards === undefined">
                            <template v-for="i in 5">
                                <tr v-bind:key="`shard_skeleton_${i}`">
                                    <td><div class="tx-table-cell-icon tx-table-cell-icon--compact"><svg/></div></td>
                                    <td><span class="skeleton">wc</span></td>
                                    <td><span class="skeleton">shard_number</span></td>
                                    <td><span class="skeleton">seqno</span></td>
                                </tr>
                            </template>
                        </tbody>

                        <tr v-for="shard in shards" v-bind:key="`shard_shard_${shard.shard}`">
                            <td>
                                <ui-link class="tx-table-cell-icon tx-table-cell-icon--compact" v-bind:to="{
                                    name: 'block',
                                    params: { ...shard }, // workchain, shard, seqno
                                }">
                                    <icon-chain fill="none"/>
                                </ui-link>
                            </td>
                            <td style="text-align: center" v-text="shard.workchain"/>
                            <td v-text="shard.shard"/>
                            <td v-text="shard.seqno"/>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="card">
                <div class="card-title" v-text="$t('block.transactions_title')"/>

                <div v-if="txHistoryEmpty" class="tx-history-empty-panel">
                    {{$t('address.tx_table.empty')}}
                </div>

                <div v-show="!txHistoryEmpty" class="tx-history-wrap">
                    <table

                        v-bind:class="{ 'tx-table all-transactions-table': isMobile, 'ui-table': !isMobile }"
                    >
                        <thead v-if="!isMobile">
                            <tr>
                                <th v-pre width="5"></th>
                                <th width="10">Seqno</th>
                                <th v-text="$t('tx.account')"/>
                                <th v-text="$t('tx.lt')"/>
                                <th v-text="$t('tx.hash')"/>
                            </tr>
                        </thead>

                        <tbody v-if="txHistoryLoading && !isMobile">
                            <template v-for="i in 5">
                                <tr v-bind:key="`tx_skeleton_${i}`">
                                    <td><div class="tx-table-cell-icon tx-table-cell-icon--compact"><svg/></div></td>
                                    <td><span class="skeleton">seqno</span></td>
                                    <td><span class="skeleton">EQDCH6...Loh</span></td>
                                    <td><span class="skeleton">lt</span></td>
                                    <td><span class="skeleton">transaction_hash</span></td>
                                </tr>
                            </template>
                        </tbody>

                        <template v-if="txHistoryLoading && isMobile">
                            <tx-skeleton-mobile  v-for="i in 5" v-bind:key="i" />
                        </template>

                        <template v-if="transactions">
                            <component
                                v-for="(tx, seqno) in transactions"
                                v-bind:is="isMobile ? 'tx-row-mobile' : 'tx-row'"
                                v-bind:key="tx.hash"
                                v-bind:seqno="seqno"
                                v-bind="tx"
                            />
                        </template>

                    </table>

                    <ui-mugen-scroll style="margin-bottom: 6px;"
                        v-bind:handler="loadMore"
                        v-bind:shouldHandle="shouldHandleScroll"
                        v-bind:showButton="showPreloader"
                        v-bind:isLoading="showPreloader"/>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import IconChain from '@img/icons/tonscan/chain-16.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconChevronDown from '@img/icons/tonscan/chevron-bottom-14.svg?vue';
import IconNextBlock from '@primer/octicons/build/svg/arrow-right-16.svg?vue';
import IconPrevBlock from '@primer/octicons/build/svg/arrow-left-16.svg?vue';
import IconLatestBlock from '@primer/octicons/build/svg/move-to-end-16.svg?vue';
import { getBlockHeader, getBlockTransactions, getLastBlock, getShards } from '~/api';
import { Address } from '~/tonweb.js';
import TxSkeletonMobile from '~/components/address/TxRowSkeletonMobile.vue';
import TxRow from './BlockTxRow.vue';
import TxRowMobile from './BlockTxRowMobile.vue';

export default {
    props: {
        workchain: [String, Number],
        shard: [String, Number],
        seqno: [String, Number],
    },

    data() {
        return {
            block: {},
            boringFieldsVisible: false,
            transactions: undefined,
            isLoadingTransactions: true,
            shards: undefined,
            incomplete: false,
            lastTransaction: null,
            lastHash: null,
        };
    },

    computed: {
        showPreloader() {
            return this.incomplete;
        },

        shouldHandleScroll() {
            return !this.isLoadingTransactions
                && this.seqno
                && this.transactions
                && this.transactions.length > 0;
        },

        isMasterChain() {
            return this.workchain.toString() === '-1';
        },

        hasPrevBlocks() {
            return (this.block.prev_blocks || []).length > 0;
        },

        txHistoryEmpty() {
            return this.transactions && this.transactions.length === 0;
        },

        txHistoryLoading() {
            return this.transactions === undefined;
        },

        prevBlockLinkParams() {
            return { ...this.$props,
                seqno: parseInt(this.seqno, 10) - 1,
            };
        },

        nextBlockLinkParams() {
            return { ...this.$props,
                seqno: parseInt(this.seqno, 10) + 1,
            };
        },

        boringFields() {
            return ['global_id', 'version', 'flags', 'after_merge', 'after_split', 'before_split', 'want_merge', 'want_split', 'validator_list_hash_short', 'gen_catchain_seqno', 'min_ref_mc_seqno', 'key_block', 'prev_key_block_seqno', 'vert_seqno'];
        },
    },

    watch: {
        $route: {
            immediate: false,
            handler() {
                this.transactions = undefined;
                this.loadData();
            },
        },
    },

    created() {
        this.loadData();
    },

    metaInfo() {
        return {
            title: this.$t('block.meta.title', { seqno: this.seqno }),
        };
    },

    methods: {
        goToLatestBlock() {
            getLastBlock().then(({ workchain, shard, seqno }) => {
                this.$router.push(this.$localizeRoute({
                    name: 'block',
                    params: { workchain, shard, seqno },
                }));
            });
        },

        reset() {
            this.block = {};
            this.boringFieldsVisible = false;
            this.transactions = undefined;
            this.shards = undefined;
        },

        getLastTxHash() {
            if (!this.transactions || this.transactions.length === 0) {
                return undefined;
            }

            const lastObject = this.transactions.at(-1);

            const lastTxAccount = new Address(lastObject.account);
            const lastTxPaginationHash = String.fromCharCode.apply(null, lastTxAccount.hashPart); // pizdec

            return btoa(lastTxPaginationHash);
        },

        getLastTxLt() {
            return this.transactions?.at(-1).lt;
        },

        async loadData() {
            const limit = 40;

            let chores = [
                getBlockTransactions({ ...this.$props,
                    offset: this.transactions?.length,
                    limit,
                }),
            ];

            // Fresh page: reset all content and new infos:
            if (this.transactions === undefined) {
                this.reset();

                // Show block info:
                chores = chores.concat([
                    getBlockHeader(this.$props).then(block => this.block = block), // eslint-disable-line no-return-assign
                ]);

                // Also show shards for masterchain:
                if (this.isMasterChain) {
                    chores = chores.concat([
                        getShards(this.$props).then(shards => this.shards = shards), // eslint-disable-line no-return-assign
                    ]);
                }
            }

            this.isLoadingTransactions = true;

            // Execute all tasks simultaneously:
            const [blockTx, ..._otherChores] = await Promise.all(chores);

            this.transactions = (this.transactions ?? []).concat(blockTx.transactions);
            this.incomplete = blockTx?.transactions?.length === limit;

            this.isLoadingTransactions = false;
        },

        async loadMore() {
            if (this.incomplete) {
                this.loadData();
            }
        },
    },

    components: {
        IconChevronDown,
        IconNextBlock,
        IconPrevBlock,
        IconLatestBlock,
        IconChain,
        IconOut,
        TxRow,
        TxRowMobile,
        TxSkeletonMobile,
    },
};
</script>
