<template>
    <section>
        <jetton-wallet v-if="isJettonWallet"
            v-bind:address="address"
            v-bind="jettonProps"/>

        <jetton-info v-else
            v-bind:address="address"
            v-bind="jettonProps"
            v-bind:imageTA="imageTA"/>
    </section>
</template>

<script>
import { truncateTicker } from '~/helpers';
import { getAccountStates, getJettonInfo } from '~/api/toncenterV3';
import JettonWallet from './JettonWallet.vue';
import JettonInfo from './JettonInfo.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
        skeletonHint: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isJettonWallet: false,
            jettonProps: {},
            isLoading: false,
            type: undefined,
            props: undefined,
            activeTab: 'transactions',
            imageTA: null,
        };
    },

    watch: {
        address() {
            this.jettonProps = {};
            this.loadData();
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        reset() {
            this.isJettonWallet = false;
            this.jettonProps = {};
        },

        // async checkAndRedirect() {
        //     try {
        //         const response = await getAccountStates(this.address);
        //         const metadata = response?.metadata[response?.accounts[0]?.address]?.token_info[0];
        //         const metadataType = metadata?.type;
        //
        //         if (metadataType === 'jetton_wallet') {
        //             await this.$router.push(this.$localizeRoute({
        //                 name: 'jetton-wallet',
        //                 params: { address: this.address },
        //             }));
        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // },

        async loadData() {
            this.reset();
            this.isLoading = true;

            try {
                await this.loadMainData();
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async loadMainData() {
            try {
                const [accountStates, jettonInfo] = await Promise.all([
                    getAccountStates(this.address),
                    getJettonInfo(this.address),
                ]);

                const { balance } = accountStates.accounts[0];
                const { jetton_masters, metadata, address_book } = jettonInfo;

                const jettonMetadata = metadata[jetton_masters[0]?.address]?.token_info[0];

                const admin = address_book[jetton_masters[0]?.admin_address]?.user_friendly;
                const total_supply = jetton_masters[0].total_supply;

                if (jettonMetadata.image) this.imageTA = jettonMetadata.image;

                this.isJettonWallet = this.skeletonHint === 'jetton_wallet';
                this.jettonProps = {
                    balance: Number(balance),
                    total_supply: Number(total_supply),
                    image_data: jettonMetadata.image,
                    image: jettonMetadata.image,
                    name: truncateTicker(jettonMetadata.name, 26),
                    symbol: truncateTicker(jettonMetadata.symbol),
                    decimals: Number(jettonMetadata?.extra?.decimals || 9),
                    description: jettonMetadata.description,
                    admin_address: admin || null,
                    metadata_url: jetton_masters[0]?.jetton_content?.uri || null,
                };

                const payload = {
                    address: this.address,
                    name: jettonMetadata.name || jettonMetadata.symbol,
                    image: jettonMetadata.image,
                    type: 'jetton',
                };
                this.$store.dispatch('presaveAddressbookItem', payload);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },

    components: {
        JettonWallet,
        JettonInfo,
    },
};

</script>
