var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tooltip",staticClass:"tip-tooltip-wrapper",class:{
        'tip-tooltip-wrapper-top': _vm.position === 'top',
        'tip-tooltip-wrapper-bottom': _vm.position === 'bottom',
        'tip-tooltip-wrapper-left': _vm.position === 'left',
        'tip-tooltip-wrapper-right': _vm.position === 'right',
        'tip-tooltip-wrap-content': _vm.isLoad === true,
    },style:(_vm.tooltipStyle)},[_c('div',{staticClass:"tip-tooltip-content"},[_vm._v("\n        "+_vm._s(_vm.value)+"\n    ")]),_vm._v(" "),(_vm.position === 'top')?_c('div',{staticClass:"tip-tooltip-top"},[_c('div',{staticClass:"tip-tooltip-top-before"},[_c('tooltip-arrow')],1)]):_vm._e(),_vm._v(" "),(_vm.position === 'bottom')?_c('div',{staticClass:"tip-tooltip-bottom"},[_c('div',{staticClass:"tip-tooltip-bottom-before"},[_c('tooltip-arrow')],1)]):_vm._e(),_vm._v(" "),(_vm.position === 'left')?_c('div',{staticClass:"tip-tooltip-left"},[_c('div',{staticClass:"tip-tooltip-left-before"},[_c('tooltip-arrow')],1)]):_vm._e(),_vm._v(" "),(_vm.position === 'right')?_c('div',{staticClass:"tip-tooltip-right"},[_c('div',{staticClass:"tip-tooltip-right-before"},[_c('tooltip-arrow')],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }