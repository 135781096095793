<template>
    <section>
        <app-header-bar/>

        <!-- As soon as this element scrolls out of the viewport, consider page "scrolled": -->
        <app-scroll-detector/>

        <main class="content">
            <router-view/>
        </main>

        <!-- Desktop ad banner -->
        <!--<ad-block v-if="!isMobile-->
        <!--              && !['index'].includes($route.name)-->
        <!--              && !$store.state.closeMemhashAd"-->
        <!--    v-bind:show-close-btn="true"-->
        <!--    class="ad-block-floating"-->
        <!--/>-->

        <!-- mobile ad banner -->
        <!--<ad-block-mobile v-if="isMobile-->
        <!--                     && !['index'].includes($route.name)-->
        <!--                     && !$store.state.closeMemhashAd"-->
        <!--    v-bind:show-close-btn="true"-->
        <!--    class="ad-block-floating--mobile"-->
        <!--/>-->

        <ui-modal
            align-top v-bind:isOpen.sync="settingsModalVisible"
            v-if="!isMobile">
            <template v-slot:header>
                {{$t('settings.title')}}
            </template>
            <app-settings/>
        </ui-modal>

        <app-menu
            v-else
            v-bind:isOpen.sync="settingsModalVisible"
            v-bind:isOpenAddressbook="openAddressbook"
            v-bind:isOpenLanguage="openLanguage"
            v-bind:isOpenCurrency="openCurrency"
            v-bind:isOpenTheme="openTheme"
            v-on:toggle-addressbook="toggleAddressbook"
            v-on:toggle-language="toggleLanguage"
            v-on:toggle-currency="toggleCurrency"
            v-on:toggle-theme="toggleTheme"
            v-on:close-popups="closePopups"
        />

        <!--<app-theme-popup-->
        <!--    v-bind:isOpenTheme="openTheme && settingsModalVisible"-->
        <!--    v-on:toggle-theme="toggleTheme"-->
        <!--/>-->

        <app-currency-popup
            v-bind:isOpenCurrency="openCurrency && settingsModalVisible"
            v-on:toggle-currency="toggleCurrency"
        />

        <!--<app-language-popup-->
        <!--    v-bind:isOpenLanguage="openLanguage && settingsModalVisible"-->
        <!--    v-on:toggle-language="toggleLanguage"-->
        <!--/>-->

        <app-toast-container/>
        <app-up-button/>
    </section>
</template>

<script>
import { computed } from 'vue';
import AppScrollDetector from '~/components/AppScrollDetector.vue';
import AppToastContainer from '~/components/AppToastContainer.vue';
import AppHeaderBar from '~/components/AppHeaderBar.vue';
import AppSettings from '~/components/settings/AppSettings.vue';
import AppUpButton from '~/components/AppUpButton.vue';
import { APP_MAIN_LOCALE } from '~/config';
import AppMenu from './menu/AppMenu.vue';
// import AppThemePopup from './menu/AppThemePopup.vue';
// import AppLanguagePopup from './menu/AppLanguagePopup.vue';
import AppCurrencyPopup from './menu/AppCurrencyPopup.vue';
// import AdBlock from './widgets/Ads/DefaultAdBlock.vue';
// import AdBlockMobile from './widgets/Ads/DefaultAdBlockMobile.vue';

export default {
    data() {
        return {
            settingsModalVisible: false,
            openLanguage: false,
            openCurrency: false,
            openTheme: false,
            neutonAdLoaded: false,
            openAddressbook: false,
        };
    },

    provide() {
        return {
            closeSettings: () => {
                this.settingsModalVisible = false;
            },
            toggleSettings: () => {
                this.settingsModalVisible = !this.settingsModalVisible;
                if (!this.settingsModalVisible) {
                    this.closePopups();
                }
            },
            isSettingsOpened: computed(() => this.settingsModalVisible),
        };
    },

    metaInfo() {
        return {
            // Add alternate lang links and canonical to every page:
            // @see https://developers.google.com/search/docs/specialty/international/localized-versions
            link: [
                ...this.$i18n.availableLocales.map(lang => Object.freeze({
                    rel: 'alternate',
                    hreflang: lang,
                    href: this.$localizedUrl(lang),
                })),
                {
                    rel: 'alternate',
                    hreflang: 'x-default',
                    href: this.$localizedUrl(APP_MAIN_LOCALE),
                },
                { rel: 'canonical', href: `${document.location.origin}${this.$route.path}` },
            ],
        };
    },

    methods: {
        toggleLanguage() {
            const currentLang = this.$store.state.appLocale;
            const langsMap = {
                ru: 'en',
                en: 'ru',
            };

            this.$store.commit('updateLocale', { locale: langsMap[currentLang] });
        },

        toggleCurrency() {
            this.openCurrency = !this.openCurrency;
            this.openTheme = false;
            this.openLanguage = false;
        },

        toggleTheme() {
            const currentTheme = this.$store.state.appTheme;
            const themesMap = {
                auto: 'dark',
                dark: 'light',
                light: 'dark',
            };

            this.$store.commit('updateTheme', themesMap[currentTheme]);
        },

        toggleAddressbook() {
            this.openAddressbook = !this.openAddressbook;
        },

        closePopups() {
            this.openLanguage = false;
            this.openCurrency = false;
            this.openTheme = false;
            this.openAddressbook = false;
        },
    },

    components: {
        AppScrollDetector,
        AppToastContainer,
        AppHeaderBar,
        AppSettings,
        AppUpButton,
        AppMenu,
        // AppThemePopup,
        // AppLanguagePopup,
        AppCurrencyPopup,
        // AdBlock,
        // AdBlockMobile,
    },
};
</script>

<style lang="scss">
.ad-block-floating {
    position: fixed;
    bottom: 20px;
    right: 3dvw;
    width: 385px!important;
    z-index: 10000;

    &--mobile {
        position: fixed;
        bottom: 16px;
        z-index: 10000;
    }
}

.neuton-enter, .neuton-leave-to {
    opacity: 0;
    transform: translateX(10px);
}

.neuton-enter-active, .neuton-leave-active {
    transition: opacity .8s, transform 1.2s ease-out;
}

.neuton-mobile-enter, .neuton-mobile-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

.neuton-mobile-enter-active, .neuton-mobile-leave-active {
    transition: opacity .8s, transform 1.2s ease-out;
}
</style>
