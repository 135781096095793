<template>
    <div class="widget">
        <div class="widget-content widget-channel-container" v-if="messages.length > 0">
            <news-feed-item v-for="message in messages"
                v-bind:key="`channel-message-${message.channelId}-${message.id}`"
                v-bind:hidden="!message.text"
                v-bind:id="message.id"
                v-bind:channelId="message.channelId"
                v-bind:channelUsername="message.channelUsername"
                v-bind:channelName="message.channelName"
                v-bind:channelAvatarVersion="message.channelAvatarVersion"
                v-bind:text="message.text"
                v-bind:date="message.date" />
        </div>
        <div class="widget-content widget-channel-container" v-else>
            <news-feed-skeleton v-for="i in 4" v-bind:key="`news-skeleton-${i}`" />
        </div>
    </div>
</template>

<script>
import { getNews } from '~/api/news';
import NewsFeedItem from './NewsFeedItem.vue';
import NewsFeedSkeleton from './NewsFeedSkeleton.vue';

export default {
    data() {
        return {
            messages: [],
        };
    },

    watch: {
        '$i18n.locale': function () {
            this.getNewsInfo();
        },
    },

    mounted() {
        this.getNewsInfo();
    },

    methods: {
        async getNewsInfo() {
            const lang = this.$i18n.locale;
            const limit = 24;

            this.messages = [];

            const channelIds = {
                ru: [1433990798, 2020833003, 1539211564, 1444152677, 1562118593, 2042152194],
                en: [1744725639, 1379327992, 1497424960, 1729567684, 1191264954, 1556132596, 1524386287],
            };

            this.messages = await getNews(channelIds[lang], limit).then(({ channels, posts }) => { // eslint-disable-line arrow-body-style
                return posts.map((item) => {
                    const channelInfo = channels[item.channel_id];
                    return {
                        channelId: item.channel_id,
                        channelUsername: channelInfo.username,
                        channelName: channelInfo.title,
                        channelAvatarVersion: channelInfo.avatar_version,
                        id: item.post_id,
                        date: item.date,
                        text: item.text,
                    };
                });
            });
        },
    },

    components: {
        NewsFeedItem,
        NewsFeedSkeleton,
    },
};
</script>

<style lang="scss">
.widget-channel-container {
    max-height: 600px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .widget {
        width: 100%;
    }
}
</style>
