var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[((_vm.type === 'nft_transfer' || _vm.type === 'nft_mint') && !_vm.isMobile)?_c('div',{staticClass:"tx-row-msg-action-badge",staticStyle:{"margin-bottom":"1px"}},[(_vm.actionDetails?.event === 'nft_purchase')?_c('div',{staticStyle:{"margin-bottom":"-1px","margin-right":"5px"}},[_vm._v("\n            "+_vm._s(_vm.$ton(_vm.actionDetails?.amount))+" TON "),_c('icon-arrow-right',{staticClass:"swap-icon-arrow",staticStyle:{"margin-left":"1px"}})],1):_vm._e(),_vm._v(" "),_c('ui-inline-nft-item',{staticStyle:{"transform":"translateY(-1px)"},attrs:{"address":_vm.actionDetails.action.nft,"event-type":_vm.type,"image-src":_vm.actionDetails.meta?.image,"nft-name":_vm.actionDetails.meta.name,"collection-name":_vm.actionDetails.meta?.collection}})],1):((_vm.type === 'nft_transfer' || _vm.type === 'nft_mint') && _vm.isMobile)?_c('div',[_vm._v("\n        1 NFT\n    ")]):(_vm.type === 'jetton_burn')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.action.amount, _vm.actionDetails.meta.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.canonizeAddress(_vm.actionDetails.meta.jetton_address),
            }
            }}},[_vm._v("\n            "+_vm._s(_vm.actionDetails.meta.symbol || _vm.shortenAddress(_vm.actionDetails.meta.jetton_address))+"\n        ")])],1):(_vm.type === 'jetton_transfer')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.action.amount, _vm.actionDetails.meta.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.actionDetails.meta.jetton_address
            }
            }}},[_vm._v("\n            "+_vm._s(_vm.actionDetails.meta.symbol || _vm.shortenAddress(_vm.actionDetails.meta.jetton_address))+"\n        ")])],1):(_vm.type === 'jetton_mint')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.amount, _vm.actionDetails.meta.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.actionDetails.meta.jetton_address
            }
            }}},[_vm._v("\n            "+_vm._s(_vm.actionDetails.meta.symbol || _vm.shortenAddress(_vm.actionDetails.meta.jetton_address))+"\n        ")])],1):(_vm.type === 'jetton_swap')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('inline-swap',{attrs:{"meta":_vm.actionDetails.meta,"round":false}})],1):(_vm.type === 'renew_dns')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',[_vm._v(_vm._s(_vm.actionDetails?.meta?.domain))])]):(_vm.type === 'dex_deposit_liquidity' || _vm.type === 'dex_withdraw_liquidity')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[(_vm.actionDetails.meta.jetton_address)?[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.meta.amount, _vm.actionDetails.meta.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                    address: _vm.canonizeAddress(_vm.actionDetails.meta.jetton_address),
                }
                }}},[_vm._v("\n                "+_vm._s(_vm.actionDetails.meta.symbol || _vm.shortenAddress(_vm.actionDetails.meta.jetton_address))+"\n            ")])]:_c('span',[_vm._v("\n            "+_vm._s(_vm.$ton(_vm.actionDetails.meta.amount))+" TON\n        ")])],2):(_vm.actionDetails?.amount)?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',[_vm._v(_vm._s(_vm.$ton(_vm.actionDetails.amount))+" TON")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }