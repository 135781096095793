<template>
    <div class="ui-round-image" v-bind:class="`ui-round-image--${size}`">
        <img v-show="showImage" class="ui-round-image__image" v-bind:src="src" v-bind:srcset="srcset" v-on:load="loaded = true">

        <div v-show="!showImage" class="ui-round-image-stub">
            <svg v-pre class="ui-round-image-stub__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="currentColor">
                <path d="M30.252 7.7c-.698-1.22-2.07-1.922-3.768-1.922H8.195c-1.706 0-3.067.707-3.77 1.922-.702 1.214-.612 2.748.244 4.218l11.434 19.86a1.44 1.44 0 0 0 2.498 0l11.407-19.863c.848-1.469.934-3.005.244-4.215zM6.91 9.135c.244-.421.976-.468 1.278-.468h7.7V26L7.143 10.494c-.154-.275-.48-.936-.239-1.358Zm20.613 1.35L18.778 26V8.667h7.706c.303 0 1.031.035 1.272.455.242.419-.09 1.099-.24 1.363z"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            required: false,
        },
        srcset: {
            type: String,
            required: false,
            default: undefined,
        },
        size: {
            type: String,
            default: 'normal',
            validator: value => ['tiny', 'normal', 'large'].includes(value),
        },
    },

    data() {
        return {
            loaded: false,
        };
    },

    computed: {
        showImage() {
            return this.loaded && this.src;
        },
    },
};
</script>

<style lang="scss">
.ui-round-image {
    color: var(--card-background);
    flex-shrink: 0;
    &__image {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}
.ui-round-image-stub {
    border-radius: 50%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--ui-round-image-color);
    box-sizing: border-box;
    &__icon {
        width: 60%;
        margin-bottom: -6%;
        fill: currentColor;
    }
}

 .ui-round-image--tiny {
    .ui-round-image-stub {
        &__icon {
            width: 60%;
        }
    }
}
</style>
