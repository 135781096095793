<template>
    <div class="price-change-indicator" v-bind:class="`price-change-indicator--${color}`">
        {{ sign }}{{ value }}%
    </div>
</template>

<script>
export default {
    props: {
        change: undefined,
        showPercent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        color() {
            if (this.change == null) return 'gray';
            return this.change < 0 ? 'red' : 'green';
        },
        sign() {
            if (this.change == null) return '';
            return this.change < 0 ? '−' : '+';
        },
        value() {
            const t = String(this.change);
            return t.replace(/-/g, '');
        },
    },
};
</script>

<style lang="scss">
.price-change-indicator {
    font-weight: 500;
    &--red {
        color: #f74b4c;
    }

    &--green {
        color: #3fb950;
    }

    &--gray {
        color: #888888;
    }
}
</style>
