<template>
    <tbody style="position: relative">
        <tr class="new-row-animation event-overview-row">
            <td class="tx-mobile-flex-content">
                <ui-link
                    class="tx-table-cell-icon tx-table-cell-icon--in"
                    v-bind:to="transactionRoute"
                    v-if="type === 'TT_BUY'"
                >
                    <icon-in />
                </ui-link>
                <ui-link
                    class="tx-table-cell-icon tx-table-cell-icon--out"
                    v-bind:to="transactionRoute"
                    v-else-if="type === 'TT_SELL'"
                >
                    <icon-out />
                </ui-link>
                <ui-link
                    class="tx-table-cell-icon tx-table-cell-icon--blue"
                    v-bind:to="transactionRoute"
                    v-else-if="type === 'liquidity_deposit'"
                >
                    <icon-stake-deposit v-once fill="none"/>
                </ui-link>
                <ui-link
                    class="tx-table-cell-icon tx-table-cell-icon--blue"
                    v-bind:to="transactionRoute"
                    v-else-if="type === 'liquidity_withdraw'"
                >
                    <icon-stake-withdraw v-once fill="none"/>
                </ui-link>
                <ui-link
                    class="tx-table-cell-icon tx-table-cell-icon--gray"
                    v-bind:to="transactionRoute"
                    v-else
                >
                    <icon-tx-chain v-once fill="none"/>
                </ui-link>

                <div class="tx-mobile-content">
                    <div
                        class="tx-block-mobile-content__wrapper tx-block-mobile-content-swap-wrapper"
                    >
                        <div
                            class="tx-block-mobile-content__row tx-block-mobile-swap-items"
                        >
                            <div class="tx-block-mobile-content__left">
                                <ui-timeago
                                    class="muted"
                                    v-bind:timestamp="timestamp"
                                />
                            </div>
                            <div
                                class="tx-block-mobile-content__right mobile-swap--red"
                            >
                                <span class="ui-inline-jetton__value">
                                    − {{ $ton(from.amount, 0, !(from.amount < 0.01)) }}
                                </span>
                                <ui-link
                                    v-if="from?.symbol !== 'TON'"
                                    v-bind:to="{
                                        name: 'jetton',
                                        params: { address: from.address },
                                    }"
                                    class="router-link__no-style jetton-history-pair"
                                >
                                    {{ from.symbol }}
                                </ui-link>
                                <template v-else>TON</template>
                            </div>
                        </div>
                        <div class="tx-block-mobile-content__row">
                            <div class="tx-block-mobile-content__left">
                                <ui-address
                                    v-bind:address="initiator"
                                    style="max-width: 160px"
                                />
                            </div>
                            <div
                                class="tx-block-mobile-content__right mobile-swap--green"
                            >
                                <span class="ui-inline-jetton__value">
                                    + {{ $ton(to.amount, 0, !(to.amount < 0.01)) }}
                                </span>
                                <ui-link
                                    v-if="to?.symbol !== 'TON'"
                                    v-bind:to="{
                                        name: 'jetton',
                                        params: { address: to.address },
                                    }"
                                    class="router-link__no-style jetton-history-pair"
                                >
                                    {{ to.symbol }}
                                </ui-link>
                                <template v-else>TON</template>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import IconIn from '@img/icons/tonscan/transactions/tx-in.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconTxChain from '@img/icons/tonscan/chain-16.svg?vue';
import IconStakeDeposit from '@img/icons/tonscan/transactions/tx-stake-deposit.svg?vue';
import IconStakeWithdraw from '@img/icons/tonscan/transactions/tx-stake-withdraw.svg?vue';

export default {
    props: {
        hash: String,
        timestamp: Number,
        from: Object,
        to: Object,
        initiator: String,
        type: String,
    },

    computed: {
        transactionRoute() {
            return { name: 'tx', params: { hash: this.hash } };
        },
    },

    components: {
        IconIn,
        IconOut,
        IconTxChain,
        IconStakeDeposit,
        IconStakeWithdraw,
    },
};
</script>

<style lang="scss">
.tx-mobile-block-row {
    margin-bottom: 4px;
}

.tx-mobile-block-row:last-child {
    margin-bottom: 0;
}

.tx-mobile-top-margin {
    margin-top: 11px;
}
</style>
