<template>
    <div class="jetton-stats">
        <div v-if="!(isMobile || isTablet)" class="jetton-stats__title">
            {{ $t('jetton.contract_info') }}
        </div>
        <div>
            <div class="jetton-stats-row">
                <div class="jetton-stats-row__name">{{ $t('address.info.address') }}</div>
                <div class="jetton-stats-row__value">
                    <ui-copy-button class="jetton-copy-items-inner" v-bind:successMessage="$t('address.info.copy_success')" v-bind:copy="address">
                        <span v-if="addressMeta.isScam" class="card-main-address-badge card-main-address-badge--scam">SCAM</span>
                        <div class="jetton-owner" style="max-width: 180px; margin-right: 5px;">
                            <ui-address v-bind:address="address" v-bind:disabled="true" v-bind:hide-name="true" />
                        </div>
                    <!-- <span>{{ truncatedAddress }}</span> -->
                    <!-- <ui-address style="max-width: 130px;" :address="address" :hideName="true" /> -->
                    </ui-copy-button>
                </div>
            </div>

            <div class="jetton-stats-row">
                <div class="jetton-stats-row__name">{{ $t('jetton.admin_address') }}</div>
                <div class="jetton-stats-row__value jetton-owner">
                    <span v-if="admin_address === undefined" class="skeleton">Ef8zMzMzMzMzM</span>
                    <span class="muted" v-else-if="!admin_address || userFriendlyAdminAddress === false">
                        {{ $t('jetton.admin_address_empty') }}
                    </span>
                    <ui-address v-else v-bind:address="userFriendlyAdminAddress" />
                </div>
            </div>

            <!-- <div class="jetton-stats-row">
            <div class="jetton-stats-row__name">{{ $t('jetton.mutable') }}</div>
            <div class="jetton-stats-row__value">
                <span v-if="is_mutable !== undefined">
                    {{ is_mutable ? $t('common.yes') : $t('common.no') }}
                </span>
                <span v-else class="skeleton skeleton--inline">Maybe</span>
            </div>
        </div> -->

            <div class="jetton-stats-row">
                <div class="jetton-stats-row__name">{{ $t('jetton.balance') }}</div>
                <div class="jetton-stats-row__value" v-if="balance !== undefined">
                    <span>{{ $ton(balance) }}</span> TON
                    <template v-if="balance !== '0'">
                        <span v-if="$store.state.exchangeRate" style="color: #717579">
                            ≈ <ui-fiat v-bind:tonValue="balance" />
                        </span>
                    </template>
                </div>
                <div v-else class="jetton-stats-row__value">
                    <span class="skeleton">00000 TON ≈ 00000 USD</span>
                </div>
            </div>

            <div class="jetton-stats-row">
                <div class="jetton-stats-row__name">{{ $t('jetton.metadata_source') }}</div>
                <div class="jetton-stats-row__value">
                    <ui-metadata-source v-bind:url="metadata_url" v-bind:loader="getRawMetadata" />
                </div>
            </div>

            <div v-if="created_at !== false" class="jetton-stats-row">
                <div class="jetton-stats-row__name">{{ $t('jetton.created_at') }}</div>
                <div class="jetton-stats-row__value jetton-owner">
                    <span v-if="created_at === undefined" class="skeleton">timestamp</span>
                    <span v-else>{{ formatValidatorDate(created_at / 1000, this.$i18n.locale) }}</span>
                </div>
            </div>

            <div class="jetton-stats-row">
                <div class="jetton-stats-row__name" v-text="$t('address.info.details')"/>
                <div class="jetton-stats-row__value">
                    <div class="card-row__value-tags">
                        <div class="card-row__value-tag-item card-row__value-tag card-row__value-tag-green"
                            v-on:mouseover="showTooltip.state = true"
                            v-on:mouseleave="showTooltip.state = false"
                        >
                            <span>{{ $t('address.info.type_active') }}</span>
                            <ui-tooltip
                                v-bind:value="$t('address.info.state')"
                                position="top"
                                v-if="showTooltip.state"
                            />
                        </div>
                        <div class="card-row__value-tag-item card-row__value-tag card-row__value-tag-gray"
                            v-on:mouseover="showTooltip.contract = true"
                            v-on:mouseleave="showTooltip.contract = false"
                        >
                            {{ $t('jetton.master') }}
                            <ui-tooltip
                                v-bind:value="$t('address.info.contract_type')"
                                position="top"
                                v-if="showTooltip.contract"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!(isMobile || isTablet)" class="jetton-buy-buttons">
            <a v-bind:href="'https://dedust.io/swap/TON/' + address"
                target="_blank"
                class="jetton-buy-buttons__btn jetton-buy-buttons__dedust"
            >
                <icon-dedust />
            </a>
            <a href="https://t.me/send?start=r-w5akh-exchange"
                target="_blank"
                class="jetton-buy-buttons__btn jetton-buy-buttons__cryptobot">
                <icon-cryptobot /> Crypto Bot
            </a>
        </div>
    </div>
</template>

<script>
import UiMetadataSource from '~/components/UiMetadataSource.vue';
import { formatValidatorDate } from '~/helpers';
import IconDedust from '@img/advertise/logos/dedust-colored.svg?inline'; // eslint-disable-line
import IconCryptobot from '@img/advertise/logos/crypto-bot-only-logo.svg?inline'; // eslint-disable-line
import {getJettonRawMetadata} from "~/api/extenderContracts"; // eslint-disable-line

export default {
    props: {
        address: String,
        admin_address: String,
        is_mutable: Boolean,
        metadata_url: String,
        balance: Number,
        created_at: Number,
    },

    data() {
        return {
            showTooltip: {
                state: false,
                contract: false,
            },
        };
    },

    computed: {
        addressMeta() {
            return this.$store.getters.getAddressMeta(this.address);
        },
        truncatedAddress() {
            return this.truncateAddress(this.address);
        },
        userFriendlyAdminAddress() {
            if (this.admin_address === '0:0000000000000000000000000000000000000000000000000000000000000000') {
                return false;
            }
            return this.admin_address;
        },
    },

    methods: {
        getRawMetadata() {
            return getJettonRawMetadata(this.address);
        },
        formatValidatorDate,
        truncateAddress(address) {
            if (address.length > 8) {
                const start = address.substring(0, 4);
                const end = address.substring(address.length - 8);
                return `${start}....${end}`;
            }
            return address;
        },
    },

    components: {
        UiMetadataSource,
        IconDedust,
        IconCryptobot,
    },
};
</script>

<style lang="scss">
.jetton-stats {
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
        text-transform: uppercase;
        font-weight: 500;
        color: var(--card-header-tab-color);
        margin-top: 12px;
    }
}

.jetton-stats-row {
    width: 100%;
    border-bottom: 1px solid var(--card-border-color);
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    align-items: flex-start;
    flex-direction: row;
    word-wrap: anywhere;
    line-height: 1.5;
    position: relative;

    &__name {
        font-weight: 500;
    }

    &__value > a {
        //font-weight: 500;
    }

    &:last-child {
        border-bottom: none;
    }
}

.jetton-owner a {
    font-weight: 400!important;
}

.jetton-owner a:hover {
    text-decoration: underline;
}

.jetton-copy-items-inner {
    display: flex;
    align-items: center;
}

.jetton-owner {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    width: 100%;
    white-space: nowrap;
    text-align: right;
}

.jetton-buy-buttons {
    display: flex;
    width: 100%;
    gap: 12px;
    height: 40px;
    margin-bottom: 16px;
    margin-top: 4px;

    &__btn {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }

    a:hover {
        opacity: .93;
        text-decoration: none!important;
    }

    &__dedust {
        color: var(--dedust-text-color);
        background-color: var(--dedust-bg-color);

        svg {
            height: 26px;
            width: auto;
        }
    }

    &__cryptobot {
        color: #fff;
        background-color: var(--big-blue-button-background);
        font-weight: 500;

        svg {
            height: 34px;
            transform: translateY(-1px);
            width: auto;
        }
    }
}
</style>
