import axios from 'axios';
import { DYOR_ENDPOINT } from '~/config.js';
import { formatTons } from '~/helpers';
import { Address } from '~/tonweb';

const http = axios.create({
    baseURL: DYOR_ENDPOINT,
});

// eslint-disable-next-line default-param-last
export const getTrendingJettonsList = async function getTrendingDyorJettons(limit = 3, offset = 0, category) {
    const params = {
        limit,
        offset,
        category,
    };

    const { data: list } = await http.get('/jettons/all', { params });

    return list;
};

export const getTopMCJettonsList = async function getDyorJettonsTopByMarketCap(limit = 3) {
    const params = {
        limit,
    };

    const { data: list } = await http.get('/jettons/trending', { params });

    return list;
};

export const getJettonInfoDyor = async function getJettonInfoByAddress(address) {
    const { data } = await http.get(`/jettons/${address}`);

    return data;
};

export const getJettonPricesDyor = async function getJettonPricesByAddressList(addressList) {
    const { data } = await http.post(
        '/prices',
        {
            address: addressList,
        },
        {
            paramsSerializer: { indexes: null },
        },
    );

    const prices = data?.items.map(item => ({
        address: item.address,
        price: parseFloat(formatTons(item.priceUsd.value, item.priceUsd.decimals)) || 0,
    }));

    return prices;
};

export const getJettonTradeHistoryDyor = async function getJettonIdByAddress(address, cursor, limit = 20) {
    const params = {
        cursor,
        limit,
    };

    const { data: pairs } = await http.get(`/swaps/${address}`, { params });

    const jettons = pairs.jettons;
    const addressBook = pairs.addressBook;
    const transactions = pairs.transactions;
    const next = pairs.next;

    const result = [];

    const jettonRawAddress = new Address(address).toString({
        userFriendly: false,
    });

    transactions.forEach((tx) => {
        const mainJetton = {
            address,
            symbol: jettons[jettonRawAddress].symbol,
            image: jettons[jettonRawAddress].image,
            amount: tx.amount.value / 10 ** tx.amount.decimals,
        };

        const counterpartJetton = {
            address: addressBook[tx.counterpart].userFriendly,
            symbol: jettons[tx.counterpart].symbol,
            image: jettons[tx.counterpart].image,
            amount: tx.counterpartAmount.value / 10 ** tx.counterpartAmount.decimals,
        };

        const directionMap = {
            TT_BUY: {
                from: counterpartJetton,
                to: mainJetton,
            },
            TT_SELL: {
                from: mainJetton,
                to: counterpartJetton,
            },
        };

        const temp = {
            hash: tx.hash,
            timestamp: new Date(tx.time).getTime(),
            from: directionMap[tx.type].from,
            to: directionMap[tx.type].to,
            initiator: tx.whoFriendly,
            type: tx.type,
        };

        result.push(temp);
    });

    return { result, next };
};
