<template>
    <span class="ui-inline-jetton" v-bind:class="{
        'ui-inline-jetton--red': (isMobile && isOut) || (txView === 'simple' && isOut),
        'ui-inline-jetton--green': (isMobile && !isOut) || (txView === 'simple' && !isOut),
        'tx-mobile-content__price': isMobile,
    }">
        <span v-if="value !== undefined" class="ui-inline-jetton__value">
            <template v-if="showPlusMinusSign">
                {{isOut ? '&minus;' : '&plus;'}}
            </template>
            {{$ton(value, decimals)}}
        </span>

        <ui-link class="ui-inline-jetton__link tx-table-jetton-link-standart" v-bind:to="{ name: 'jetton', params: { address }}">
            {{ truncateTicker(ticker) }}
            <ui-round-image size="tiny" class="ui-inline-jetton__avatar"
                v-if="showImage"
                v-bind:src="imageData || image.w72"/>
        </ui-link>
    </span>
</template>

<script>
import { truncateTicker } from '../../helpers';

export default {
    methods: { truncateTicker },
    props: {
        address: String,
        value: String,
        name: String,
        decimals: Number,
        symbol: String,
        isOut: Boolean,
        showImage: Boolean,
        showSign: {
            type: Boolean,
            default: undefined,
        },
        imageData: String,
        image: {
            type: [Object, String],
            default: () => ({}),
        },
    },

    computed: {
        ticker() {
            if (this.address === 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs') {
                return 'USD₮';
            }

            if (this.symbol.length === 48) {
                return this.address.substring(0, 3) + '…' + this.address.substring(45);
            }

            return this.symbol.toUpperCase();
        },

        showPlusMinusSign() {
            return this.showSign ?? this.isMobile;
        },

        txView() {
            return this.$store.state.transactionViewData;
        },
    },
};
</script>

<style lang="scss">
.ui-inline-jetton {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    &__value {
        margin-right: 4px;
    }
    &__link {
        display: flex;
        align-items: center;
    }
    &__avatar {
        margin: -4px -1px -4px 6px;
        width: 20px;
        height: 20px;
    }
    &--green {
        color: var(--badge-green-color);
    }
    &--red {
        color: var(--badge-red-color);
    }
    &--green, &--red {
        // if the amount is colored, the link should also be colored:
        .ui-inline-jetton__link {
            color: currentColor;
        }
    }
}
</style>
