<template>
    <section>
        <div class="tx-history-wrap desktop-table tx-history-nft-table">
            <table class="tx-table">
                <thead v-if="!isMobile && !isError">
                    <tr>
                        <!-- <th v-pre width="40"></th> -->
                        <th>
                            <div class="tx-table__cell tx-table__cell--align-left">{{ $t('nft.item.action') }}</div>
                        </th>
                        <th>
                            <div class="tx-table__cell tx-table__cell--align-left">{{ $t('nft.item.value_tx') }}</div>
                        </th>
                        <th>
                            <div class="tx-table__cell tx-table__cell--align-left">{{ $t('transactionPage.route') }}</div>
                        </th>
                        <!-- <th width="50"></th>
                        <th>
                            <div class="tx-table__cell">{{ $t('nft.item.recipient_tx') }}</div>
                        </th> -->
                        <th width="100">
                            <div class="tx-table__cell tx-table__cell--align-right">{{ $t('nft.item.age_tx') }}</div>
                        </th>
                    </tr>
                </thead>
                <template v-if="history.length === 0 && !isError">
                    <tx-history-skeleton
                        v-for="i in 8"
                        v-bind:key="`tx_skeleton_${i}`"
                    />
                </template>
                <template v-else-if="isError">
                    <div class="tx-history-empty-panel" v-text="$t('address.tx_table.empty')" />
                </template>
                <template v-else>
                    <template v-if="!isMobile">
                        <tx-history
                            v-for="(item, index) in history" v-bind:key="index"
                            v-bind:hash="item.hash"
                            v-bind:time="item.time"
                            v-bind:type="item.typeData.type"
                            v-bind:owner="item.typeData.owner"
                            v-bind:oldOwner="item.typeData.oldOwner"
                            v-bind:newOwner="item.typeData.newOwner"
                            v-bind:price="item.typeData.price"
                        />
                    </template>
                    <template  v-else>
                        <tx-history-mobile
                            v-for="(item) in history" v-bind:key="item.time"
                            v-bind:hash="item.hash"
                            v-bind:time="item.time"
                            v-bind:type="item.typeData.type"
                            v-bind:owner="item.typeData.owner"
                            v-bind:oldOwner="item.typeData.oldOwner"
                            v-bind:newOwner="item.typeData.newOwner"
                            v-bind:price="item.typeData.price"
                        />
                    </template>
                </template>
            </table>
        </div>
        <ui-mugen-scroll
            v-bind:handler="loadMore"
            v-bind:shouldHandle="shouldHandleScroll"
            v-bind:showButton="showPreloader"
            v-bind:isLoading="isLoading"
        />
    </section>
</template>

<script>
import { getItemHistory } from '~/api/getgems.js';
import TxHistory from './TxHistory.vue';
import TxHistoryMobile from './TxHistoryMobile.vue';
import TxHistorySkeleton from './TxHistorySkeleton.vue';

export default {
    props: {
        address: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            history: [],
            isLoading: true,
            isError: false,
            cursor: undefined,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading
                && this.address
                && this.hasMore
                && this.history.length > 0;
        },

        showPreloader() {
            return this.address
                && this.history.length > 0
                && this.hasMore;
        },

        hasMore() {
            return this.cursor !== null;
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        loadData() {
            this.isLoading = true;

            getItemHistory(this.address, 24, this.cursor).then(({ cursor, items }) => {
                this.history = this.history.concat(items.map(Object.freeze));
                this.cursor = cursor;
                if (this.history?.length === 0) {
                    this.isError = true;
                    this.changeTab();
                }
            }).catch(() => {
                this.isError = true;
                this.changeTab();
            }).finally(() => {
                this.isLoading = false;
            });
        },

        changeTab() {
            const currentHash = this.$route.hash;

            if (currentHash !== '#transactions') {
                this.$router.push({
                    name: 'nft', hash: '#transactions',
                    params: { address: this.address, dontRefresh: true },
                });
            }
        },

        loadMore() {
            if (this.cursor) {
                this.loadData();
            }
        },
    },

    components: {
        TxHistory, TxHistoryMobile, TxHistorySkeleton,
    },
};
</script>

<style lang="scss">
.tx-table {
    &__badge {
        &--burn {
            color: var(--badge-red-color)!important;
            background-color: var(--badge-red-background-color)!important;
        }
        &--sale {
            color: var(--badge-orange-color)!important;
            background-color: var(--badge-orange-background-color)!important;
        }
        &--sent {
            color: var(--badge-blue-color)!important;
            background-color: var(--badge-blue-background-color)!important;
        }
        &--bid {
            color: var(--badge-orange-color)!important;
            background-color: var(--badge-orange-background-color)!important;
        }
        &--mint {
            color: var(--badge-green-color)!important;
            background-color: var(--badge-green-background-color)!important;
        }
        &--cancel {
            color: var(--badge-red-color)!important;
            background-color: var(--badge-red-background-color)!important;
        }
        &--sold {
            color: var(--badge-green-color)!important;
            background-color: var(--badge-green-background-color)!important;
        }
        &--value {
            text-transform: uppercase;
        }
        &--items {
            display: flex;
            align-items: center;
            padding: 0 13px 0 8px;
            gap: 5px;
        }
        &--icon {
            svg {
                display: block;
                width: 18px;
            }
        }
    }
}
</style>
