<template>
    <tr>
        <td class="jetton-list-link">
            <ui-link v-bind:to="{ name: 'jetton', params: { address: jetton }}">
                <ui-round-image class="jetton-list__icon" size="tiny" v-bind:src="image"/>
                <span style="font-weight: 500">{{ name || jetton }}</span>
            </ui-link>
            <div class="muted jetton-list-ticker">{{ symbol }}</div>
        </td>
        <td style="max-width: 300px;">
            <b>{{ $ton(balance, decimals) }}</b>
            <span style="margin-left: 2px;">{{ symbol }}</span>
            <span style="margin-left: 2px;" class="muted">
                &asymp;
                <ui-format-price v-bind:price="balancePrice" v-bind:symbol="'$'"/>
            </span>
        </td>
        <td class="ui-table__cell--align-right">
            <ui-address v-bind:address="jettonWallet"/>
        </td>
    </tr>
</template>

<script>
import { truncateTicker } from '~/helpers.js';

export default {
    props: {
        image: String,
        name: String,
        symbol: String,
        balance: String,
        decimals: [Number, String],
        jetton: String,
        jettonWallet: String,
        jettonPrice: Number,
        balancePrice: Number,
    },

    methods: {
        truncateTicker,
        // getJettonRate(wallet) {
        //     const jettonsCount = parseFloat(formatTons(wallet.balance, wallet.jetton_meta.decimals).replace(/\s/g, '').replace(',', '.'));
        //
        //     const rate = this.rates.filter(({ id }) => id === wallet.jetton_address);
        //
        //     if (rate.length > 0) {
        //         return rate[0].price * jettonsCount;
        //     }
        //
        //     return null;
        // },
    },
};
</script>

<style lang="scss">
.price-token-table {
    font-weight: 500;
}
.jetton-list-link a {
    display: inline-flex;
    align-items: center;
}
.jetton-list-ticker {
    margin-left: 6px;
}
</style>
