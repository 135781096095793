<template>
    <div
        class="tip-tooltip-wrapper"
        ref="tooltip"
        v-bind:class="{
            'tip-tooltip-wrapper-top': position === 'top',
            'tip-tooltip-wrapper-bottom': position === 'bottom',
            'tip-tooltip-wrapper-left': position === 'left',
            'tip-tooltip-wrapper-right': position === 'right',
            'tip-tooltip-wrap-content': isLoad === true,
        }"
        v-bind:style="tooltipStyle"
    >
        <div class="tip-tooltip-content">
            {{ value }}
        </div>
        <div v-if="position === 'top'" class="tip-tooltip-top">
            <div class="tip-tooltip-top-before">
                <tooltip-arrow />
            </div>
        </div>
        <div v-if="position === 'bottom'" class="tip-tooltip-bottom">
            <div class="tip-tooltip-bottom-before">
                <tooltip-arrow />
            </div>
        </div>
        <div v-if="position === 'left'" class="tip-tooltip-left">
            <div class="tip-tooltip-left-before">
                <tooltip-arrow />
            </div>
        </div>
        <div v-if="position === 'right'" class="tip-tooltip-right">
            <div class="tip-tooltip-right-before">
                <tooltip-arrow />
            </div>
        </div>
    </div>
</template>

<script>
import TooltipArrow from '@img/icons/tonscan/tooltip-bottom.svg?vue';

export default {
    props: {
        value: {
            type: String,
        },
        position: {
            type: String,
            default: 'bottom',
        },
    },

    data() {
        return {
            tooltipWidth: 0,
            isLoad: false,
        };
    },

    computed: {
        tooltipStyle() {
            if (this.position === 'bottom') {
                return null;
            }

            return {
                position: 'absolute',
                ...(this.position === 'right' ? { right: `-${this.tooltipWidth - 3}px` } : null),
                ...(this.position === 'left' ? { left: `-${this.tooltipWidth - 3}px` } : null),
            };
        },
    },

    mounted() {
        this.calculateTooltipWidth();
    },

    watch: {
        value: 'calculateTooltipWidth',
    },

    methods: {
        calculateTooltipWidth() {
            this.$nextTick(() => {
                const tooltipElement = this.$refs.tooltip;
                this.tooltipWidth = tooltipElement.clientWidth + 15;
                this.isLoad = true;
            });
        },
    },

    components: {
        TooltipArrow,
    },
};
</script>

<style lang="scss">
.tip-tooltip-wrap-content {
    text-wrap: wrap;
}

.tip-tooltip-wrapper {
    position: absolute;
    transform: translate(-50%, 0%);
    top: -37px;
    left: 50%;
    padding: 6px 15px;
    border-radius: 5px;
    font-size: 13px;
    background: #000;
    color: #FFF;
    background-clip: border-box;
    box-shadow: 0 .3rem 0.8rem var(--card-box-shadow-color);
    max-width: 400px;
    z-index: 1001;
}

.tip-tooltip-wrapper-top {
    white-space: nowrap;
    //transform: translate(-50%, -50%);
    top: -44px;
}

.tip-tooltip-wrapper-bottom {
    // overflow-wrap: break-word;
    white-space: nowrap;
    top: 37px;
}

.tip-tooltip-wrapper-left {
    transform: translate(0%, -50%);
    top: 50%;
    right: auto;
    left: 0;
}

.tip-tooltip-wrapper-right {
    transform: translate(0%, -50%);
    top: 50%;
    right: 0;
    left: auto;
}

.tip-tooltip-top-before {
    position: absolute;
    width: 25px;
    height: 5px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
    z-index: 0;
}

.tip-tooltip-bottom-before {
    position: absolute;
    width: 25px;
    height: 5px;
    top: -1px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    color: #000;
    z-index: 0;
}

.tip-tooltip-left-before {
    position: absolute;
    width: 22px;
    height: 6px;
    bottom: 50%;
    top: 50%;
    right: -8px;
    transform: translateY(-50%) rotate(-90deg);
    color: #000;
    z-index: 0;
}

.tip-tooltip-right-before {
    position: absolute;
    width: 22px;
    height: 6px;
    bottom: 50%;
    top: 50%;
    left: -8px;
    transform: translateY(-50%) rotate(90deg);
    color: #000;
    z-index: 0;
}
</style>
