<template>
    <tbody style="position: relative;" v-bind:class="{'tx-table__td-no-top-border': actionDetails?.action?.type === 'dex:liquidity_asset_2'}">
        <tr class="new-row-animation event-overview-row">
            <td class="tx-mobile-flex-content">

                <div class="tx-table-cell-icon tx-table-cell-icon--service">
                    <event-overview-icon v-bind:event="eventName" />
                </div>

                <div class="tx-mobile-content">
                    <div class="tx-block-mobile-content__wrapper tx-block-mobile-content-swap-wrapper">
                        <div
                            class="tx-block-mobile-content__row"
                            v-bind:class="type === 'nft_transfer' ? 'tx-block-nft-transfer' : ''"
                        >
                            <div class="tx-block-mobile-content__left" style="display: flex; flex-direction: column;">
                                <span class="muted tx-block-swap-name">
                                    {{ $t(`events.${eventName}`) }}
                                </span>
                            </div>
                            <div class="tx-block-mobile-content__right">
                                <event-overview-badge
                                    v-bind:type="type"
                                    v-bind:actionDetails="actionDetails"
                                />
                            </div>
                        </div>
                        <div class="tx-block-mobile-content__row">
                            <div class="tx-block-mobile-content__address">
                                <ui-address
                                    class="ui-address-event-overview"
                                    v-bind:address="processedAddressesFrom"
                                    v-bind:alias="processedNameFrom"
                                />
                                <div class="tx-table-action-icon" v-if="event">
                                    <arrow-right />
                                </div>
                                <ui-address v-if="event"
                                    class="ui-address-event-overview"
                                    v-bind:address="processedAddressesTo"
                                    v-bind:alias="processedNameTo"
                                />
                            </div>
                        </div>

                        <div v-if="type === 'nft_transfer' || type === 'nft_mint'" class="big-nft-container" style="margin-top: 12px;">
                            <ui-inline-nft-item
                                style="transform: translateY(-1px)"
                                v-bind:address="actionDetails.action.nft"
                                v-bind:event-type="type"
                                v-bind:image-src="actionDetails.meta?.image"
                                v-bind:nft-name="actionDetails.meta.name"
                                v-bind:collection-name="actionDetails.meta?.collection"
                                v-bind:is-out="true"
                            />
                        </div>

                        <template v-if="['jetton_transfer', 'nft_transfer', 'ton_transfer'].includes(type)
                            && (actionDetails?.encrypted || actionDetails?.message)">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span v-if="actionDetails?.encrypted" class="tx-mobile-comment__contents muted">{{ $t('tx.encrypted_message') }}</span>
                                        <span v-else class="tx-mobile-comment__contents">{{ actionDetails?.message }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="actionDetails?.meta?.provider">
                            <div class="tx-block-mobile-content__row">
                                <div class="tx-mobile-content__bottom">
                                    <div class="tx-mobile-comment">
                                        <span class="tx-mobile-comment__contents">Provider: {{ actionDetails.meta.provider }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';
import EventOverviewIcon from '~/components/tx/Tabs/EventOverviewIcon.vue';
import UiInlineNftItem from '~/components/address/TxRowInlineNft.vue';
import EventOverviewBadge from './EventOverviewBadge.vue';

export default {
    props: {
        action: Object,
        actionDetails: Object,
        type: String,
        event: Boolean,
    },

    computed: {
        eventName() {
            return this.actionDetails?.event === 'nft_purchase' ? 'nft_purchase' : this.type;
        },

        processedAddressesFrom() {
            return this.actionDetails.from;
        },

        processedNameFrom() {
            return this.actionDetails.source_alias;
        },

        processedAddressesTo() {
            return this.actionDetails.to;
        },

        processedNameTo() {
            return this.actionDetails.destination_alias;
        },
    },

    components: {
        UiInlineNftItem,
        EventOverviewIcon,
        ArrowRight,
        EventOverviewBadge,
    },
};
</script>

<style lang="scss">
.event-overview-row {
    .ui-inline-nft {
        padding: 0!important;
        margin: 0!important;
        border-radius: 8px!important;

        &:hover {
            background: var(--nft-preview-hover-background)!important;
            color: var(--nft-preview-hover-color)!important;
        }
    }
}

.tx-block-mobile-swap-items {
    align-items: center;
}

.tx-block-mobile-content-swap-wrapper {
    display: flex;
    flex-direction: column;
}

.tx-block-mobile-content__address {
    display: inline-flex;
    min-width: 0;
    flex-shrink: 1;
}

.tx-block-swap-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tx-block-nft-transfer {
    //padding-bottom: 5px;
}

.ui-address-event-overview {
    max-width: 180px;
}

@media (max-width: 599px) {
    .tx-table-action-icon {
        margin: 0 5px;
    }
}
</style>
