<template>
    <div
        class="card-status"
        v-bind:class="{
            'card-status__skeleton': traceStatus === undefined,
            'card-status__pending': isPending === true,
            'card-status__success': traceStatus === true && isPending === false,
            'card-status__failed': traceStatus === false && isPending === false,
        }"
    >
        <div class="card-status-items">
            <div class="card-status-icon">
                <div v-if="traceStatus === undefined"  class="card-status-skeleton-image">
                    <span class="skeleton">Unknown</span>
                </div>
                <template v-else-if="isPending">
                    <icon-tx-pending v-once class="tx-pending-icon" />
                </template>
                <template v-else-if="traceStatus">
                    <icon-success />
                </template>
                <template v-else>
                    <icon-failed />
                </template>
            </div>
            <div class="card-status-body">
                <div class="card-status-info">
                    <div class="card-status-title">
                        <span v-if="traceStatus === undefined" class="skeleton">Unknown</span>
                        <template v-else-if="isPending">
                            {{ $t('address.tx_table.pending') }}
                        </template>
                        <template v-else-if="traceStatus">
                            {{ $t('tx.status_success') }}
                        </template>
                        <template v-else>
                            {{ $t('tx.status_failed') }}
                        </template>
                    </div>
                    <div class="card-status-value" v-if="!isMobile">
                        <span v-if="traceStatus === undefined" class="skeleton">EQDCHV6...LoH</span>
                        <template v-else-if="isPending">
                            <span class="card-status-st-text">{{ $t('tx.status_pending_description') }}</span>
                        </template>
                        <template v-else-if="traceStatus">
                            <div class="card-status-st-text" v-if="simplePreview">
                                <ui-address
                                    style="max-width: 160px;"
                                    v-bind:address="simplePreview.from"
                                    v-bind:alias="simplePreview.source_alias"
                                />
                                <span v-if="simplePreview.type === 'jetton_burn'">{{ $t('transactionPage.burned') }}</span>
                                <span v-else-if="simplePreview.type === 'jetton_swap'">{{ $t('transactionPage.exchanged') }}</span>
                                <span v-else>{{ $t('transactionPage.sent') }}</span>
                                <span class="simple-preview__amount" v-if="simplePreview.type === 'jetton_transfer' || simplePreview.type === 'jetton_burn'">
                                    <ui-inline-jetton
                                        v-bind:address="simplePreview.meta?.jetton_address"
                                        v-bind:decimals="simplePreview.meta?.decimals"
                                        v-bind:symbol="simplePreview.meta?.symbol"
                                        v-bind:value="simplePreview.amount"
                                    />
                                </span>
                                <div class="simple-preview__nft" v-else-if="simplePreview.type === 'nft_transfer'">
                                    <ui-inline-nft
                                        v-bind:address="simplePreview.action.nft"
                                        v-bind:event-type="simplePreview.type"
                                        v-bind:image-src="simplePreview.meta?.image"
                                        v-bind:nft-name="simplePreview.meta?.name"
                                    />
                                </div>
                                <span class="simple-preview__amount" v-else-if="simplePreview.type === 'jetton_swap'">
                                    <inline-swap v-bind:meta="simplePreview.meta" v-bind:round="true" />
                                </span>

                                <span class="simple-preview__amount" v-else>{{ $ton(simplePreview.amount) }} TON</span>
                                <template v-if="simplePreview.type !== 'jetton_burn'">
                                    <span v-if="simplePreview.type === 'jetton_swap' && simplePreview.meta?.dex">
                                        {{ $t('transactionPage.on') }}
                                        <span class="simple-preview__amount" style="text-transform: capitalize">
                                            {{ simplePreview.meta.dex.split('_')[0] }}
                                        </span>
                                    </span>
                                    <span v-else>
                                        {{ $t('transactionPage.to') }}
                                        <ui-address
                                            style="max-width: 160px;"
                                            v-bind:address="simplePreview.to"
                                            v-bind:alias="simplePreview.destination_alias"
                                        />
                                    </span>
                                </template>
                            </div>
                            <template v-else>
                                <span class="card-status-st-text">{{ $t('tx.status_success_description') }}</span>
                            </template>
                        </template>
                        <template v-else>
                            <span class="card-status-st-text">{{ $t('tx.status_failed_description') }}</span>
                        </template>
                    </div>
                </div>
                <div class="card-status-time">
                    <span v-if="traceStatus === undefined" class="skeleton">Unknown</span>
                    <span v-else-if="timestamp > 0">
                        <!-- <ui-datetime v-bind:timestamp="timestamp" v-bind:monthFormatDate="true" /> -->
                        {{ formatValidatorDate(timestamp, this.$i18n.locale) }}
                    </span>
                    <template v-else-if="isPending && isMobile">
                        <span class="card-status-st-text">{{ $t('tx.status_pending_description') }}</span>
                    </template>
                    <div style="margin-top: 8px" v-if="duration && !isMobile && !isPending">
                        <icon-duration class="card-status-time__icon" /><ui-date-period v-bind:seconds="duration" />
                    </div>
                </div>
            </div>
            <div class="card-status-time" v-if="duration && isMobile && !isPending">
                <icon-duration style="padding-top: 2px" class="card-status-time__icon" /><ui-date-period v-bind:seconds="duration" />
            </div>
        </div>
    </div>
</template>

<script>
import IconSuccess from '@img/icons/status/success.svg?inline';
import IconFailed from '@img/icons/status/failed.svg?inline';
import IconTxPending from '@img/icons/tonscan/round-loader.svg?inline';
import IconDuration from '@img/icons/tonscan/clock.svg?inline';
import UiInlineNft from '~/components/address/TxRowInlineNft.vue';
import UiInlineJetton from '~/components/address/TxRowInlineJetton.vue';
import { formatValidatorDate } from '~/helpers.js';
import { canonizeAddress } from '~/tonweb.js';
import InlineSwap from '~/components/address/TxRowInlineSwap.vue';

export default {
    props: {
        isSuccess: Boolean,
        isPending: Boolean,
        computeExitCode: String,
        actionResultCode: String,
        address: String,
        timestamp: Number,
        traceStatus: Boolean,
        traceDetails: Object,
        traceActions: Array,
        duration: Number,
    },

    data() {
        return {
            actionTransfer: false,
            actionDetails: false,
            actionType: null,
        };
    },

    computed: {
        simplePreview() {
            const allowedActions = ['ton_transfer', 'jetton_transfer', 'nft_transfer', 'jetton_burn', 'jetton_swap'];
            const filteredActions = this.traceActions.filter(action => allowedActions.includes(action.type));
            if (filteredActions.length === 1) {
                const preview = filteredActions[0].simple_preview;
                const type = filteredActions[0].type;
                let amount;

                switch (type) {
                    case 'jetton_transfer':
                    case 'jetton_burn':
                        amount = preview.action?.amount;
                        break;
                    case 'nft_transfer':
                        amount = preview.action?.amount;
                        break;
                    default:
                        amount = preview.amount;
                        break;
                }

                const stonfi = 'EQB3ncyBUTjZUA5EnFKR5_EnOMI9V1tTEAAPaiU71gc4TiUt';
                if (preview.from === stonfi && preview.to === stonfi) return null;

                return {
                    from: preview.from,
                    source_alias: preview?.source_alias,
                    to: preview.to,
                    destination_alias: preview?.destination_alias,
                    amount,
                    meta: preview?.meta,
                    action: preview.action,
                    type,
                };
            }

            return null;
        },
    },

    watch: {
        traceDetails: {
            handler: 'handleTraceDetailsChange',
            deep: true,
            immediate: true,
        },
    },

    methods: {
        formatValidatorDate,
        canonizeAddress,
        handleTraceDetailsChange(newDetails) {
            if (newDetails && newDetails.actions.length === 1 && newDetails.actions[0] && newDetails.actions[0].type === 'TonTransfer') {
                this.actionTransfer = true;
                const action = newDetails.actions[0].TonTransfer;

                const sender = {
                    ...action.sender,
                    address: canonizeAddress(action.sender.address, { type: action.sender.is_wallet ? 'wallet' : undefined }),
                };

                const recipient = {
                    ...action.recipient,
                    address: canonizeAddress(action.recipient.address, { type: action.recipient.is_wallet ? 'wallet' : undefined }),
                };

                this.actionDetails = {
                    ...action,
                    sender,
                    recipient,
                };

                this.actionType = 'TonTransfer';
            } else if (newDetails && newDetails.actions.length === 1 && newDetails.actions[0] && newDetails.actions[0].type === 'JettonTransfer') {
                this.actionTransfer = true;
                const action = newDetails.actions[0].JettonTransfer;

                const sender = {
                    ...action.sender,
                    address: canonizeAddress(action.sender.address, { type: action.sender.is_wallet ? 'wallet' : undefined }),
                };

                const recipient = {
                    ...action.recipient,
                    address: canonizeAddress(action.recipient.address, { type: action.recipient.is_wallet ? 'wallet' : undefined }),
                };

                this.actionDetails = {
                    ...action,
                    sender,
                    recipient,
                };

                this.actionType = 'JettonTransfer';
            } else if (newDetails && newDetails.actions.length === 1 && newDetails.actions[0] && newDetails.actions[0].type === 'JettonSwap') {
                this.actionTransfer = true;
                const action = newDetails.actions[0].JettonSwap;

                const router = {
                    ...action.router,
                    address: canonizeAddress(action.router.address, { type: action.router.is_wallet ? 'wallet' : undefined }),
                };

                const user_wallet = {
                    ...action.user_wallet,
                    address: canonizeAddress(action.user_wallet.address, { type: action.user_wallet.is_wallet ? 'wallet' : undefined }),
                };

                this.actionDetails = {
                    ...action,
                    router,
                    user_wallet,
                };

                this.actionType = 'JettonSwap';
            } else {
                this.actionTransfer = false;
                this.actionDetails = null;
            }
        },
    },

    components: {
        InlineSwap,
        IconSuccess,
        IconFailed,
        IconTxPending,
        IconDuration,
        UiInlineNft,
        UiInlineJetton,
    },
};
</script>

<style lang="scss">
.card-status {
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 20px;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    &__skeleton {
        background: var(--success-skeleton-transaction-background);
        border: 1px solid var(--success-skeleton-transaction-border);
    }
    &__success {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        .card-status-st-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
        .card-status-title {
            color: var(--success-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--body-muted-text-color);
            white-space: nowrap;
            text-align: right;
        }

        .card-status-icon {
            color: var(--success-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
    }
    &__failed {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        .card-status-st-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
        .card-status-title {
            color: var(--fail-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--body-muted-text-color);
            text-align: right;
        }

        .card-status-icon {
            color: var(--fail-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
    }
    &__pending {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        .card-status-st-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
        .card-status-title {
            color: var(--card-header-color);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--body-muted-text-color);
            white-space: nowrap;
        }

        .card-status-icon {
            color: var(--tx-table-icon-color);
            background-color: var(--body-light-muted-color);
            border-radius: 50px;
            box-sizing: border-box;
            padding: 7px;
            //svg {
            //    width: 24px;
            //    height: 24px;
            //}
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
    }
    .simple-preview__nft {
        display: inline-block;
        transform: translateY(1px);
        margin: 0 4px;
        color: var(--body-text-color);
    }
    .simple-preview__amount {
        font-weight: 500;
        color: var(--body-text-color);
    }
}

.card-status-address {
    width: 150px;
    font-size: 14px;
}

.card-status-items {
    display: flex;
    gap: 20px;
    align-items: center;
}

@media all and (max-width: 599px) {
    .card-status-items {
        align-items: flex-start;
    }
}

.card-status-icon {
    width: 40px;
    svg {
        display: block;
    }
}

.card-status-body {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}

.card-status-title {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 500;
}

.card-status-skeleton-image {
    width: 43px;
    height: 43px;
    border-radius: 50px;
    overflow: hidden;
}

.card-status-skeleton-image .skeleton {
    height: 100%;
    width: 100%;
}

.status-desc-address {
    max-width: 240px;
}

.card-status-time__icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: -3px;
}

.status-desc-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 14px;
}

.status-desc-count {
    font-weight: 500;
}

.status-desc-ticker {
    font-weight: 500;
}

@media all and (max-width: 599px) {

    .card-status-body {
        flex-direction: column;
        flex-grow: 1;
    }
    .card-status__success {
        .card-status-time {
            text-align: left;
        }
    }
    .card-status__failed {
        .card-status-time {
            text-align: left;
        }
    }

    .card-status-address {
        margin: 10px auto 10px auto;
    }
}
</style>
