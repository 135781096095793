<template>
    <section>
        <template v-if="items.length === 0">
            <div v-if="isLoading" class="user-nfts">
                <template v-for="i in 12">
                    <div v-bind:key="`tab_user_nft_skeleton_${i}`" class="user-nft user-nft--skeleton">
                        <div class="user-nft__image">
                            <div class="skeleton"/>
                        </div>
                        <footer class="user-nft__footer">
                            <h4 class="user-nft__collection">
                                <span class="user-nft__collection__text skeleton">NFT collection name</span>
                            </h4>
                            <h3 class="user-nft__name">
                                <span class="skeleton">NFT name</span>
                            </h3>
                        </footer>
                    </div>
                </template>
            </div>

            <div v-else class="tx-history-empty-panel">
                {{ $t('address.nfts.no_nfts') }}

                <a v-bind:href="$t('address.nfts.buy_nft_link')" class="user-nft__btn" target="_blank">
                    {{ $t('address.nfts.buy_nft') }}
                </a>
            </div>
        </template>

        <section v-else class="user-nfts">
            <tab-user-nft-item
                v-for="(item, idx) in items"
                v-bind:idx="idx"
                v-bind:address="addressBook[item.address].user_friendly"
                v-bind:content="metadata[item.address].token_info[0]"
                v-bind:collection="metadata[item?.collection_address]?.token_info[0]"
                v-bind:collection-address="addressBook[item?.collection_address]?.user_friendly || null"
                v-bind:key="`nft_item_${idx}`"
            />
        </section>

        <ui-mugen-scroll
            v-if="items.length !== 0"
            style="margin-bottom: 6px;"
            v-bind:handler="loadData"
            v-bind:shouldHandle="shouldHandleScroll"
            v-bind:showButton="showPreloader"
            v-bind:isLoading="isLoading"/>
    </section>
</template>

<script>
import { getNFTsByOwnerAddress } from '~/api/toncenterV3';
import TabUserNftItem from './TabUserNftItem.vue';

export default {
    props: {
        address: String,
    },

    data() {
        return {
            items: [],
            addressBook: {},
            metadata: {},
            isLoading: true,
            hasMore: true,
            limit: 12,
            offset: 0,
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading
                && this.address
                && this.hasMore
                && this.items.length > 0;
        },

        showPreloader() {
            return this.address
                && this.items.length > 0
                && this.hasMore;
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        async loadData() {
            this.isLoading = true;

            const nfts = await getNFTsByOwnerAddress(this.address, this.limit, this.offset);
            const items = nfts?.nft_items;
            this.items = this.items.concat(items);
            this.addressBook = Object.assign(this.addressBook, nfts?.address_book);
            this.metadata = Object.assign(this.metadata, nfts?.metadata);
            this.hasMore = items.length >= this.limit;
            this.offset += items.length || 0;
            this.isLoading = false;
        },
    },

    components: {
        TabUserNftItem,
    },
};
</script>

<style lang="scss">
.user-nfts {
    padding: 24px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    @media all and (max-width: 1200px) {
        & {
            padding: 18px;
            grid-column-gap: 18px;
            grid-row-gap: 18px;
        }
    }
    @media all and (max-width: 900px) {
        & { grid-template-columns: 1fr 1fr 1fr; }
    }
    @media all and (max-width: 600px) {
        & { grid-template-columns: 1fr 1fr; }
    }
    @media all and (max-width: 480px) {
        & {
            padding: 14px;
            grid-column-gap: 14px;
            grid-row-gap: 14px;
        }
    }
}

.user-nfts-empty-panel {
    padding: 30px 24px 24px;
    font-weight: 500;
    text-align: center;
}

.user-nft {
    position: relative;
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    background-color: var(--user-nft-list-item-background);
    box-shadow: 1px 1px var(--user-nft-list-item-box-shadow-blur) rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    overflow: hidden;
    transition: .2s background ease;
    &:hover {
        text-decoration: none;
    }
    @media (hover: hover) {
        &:hover {
            background-color: var(--user-nft-list-item-hover-background);
            .user-nft__image__img {
                transform: scale(1.08);
            }
        }
    }
    &--skeleton {
        pointer-events: none;
        user-select: none;
    }
    &__image {
        font-size: 0;
        width: 100%;
        overflow: hidden;
        display: block;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        aspect-ratio: 1 / 1;
        &__img {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: none;
            transition: .2s transform ease;
        }
        &__broken {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            svg {
                width: 60px;
                height: 60px;
            }
        }
        .skeleton {
            border-radius: 0;
            background-image: none;
            box-shadow: none;
            aspect-ratio: 1 / 1;
            width: 100%;
        }
    }
    &__footer {
        padding: 12px 12px 10px;
    }
    &__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-weight: 500;
        font-size: 16px;
    }
    &__collection {
        padding: 4px 4px 8px 4px;
        margin: -4px;
        font-weight: normal;
        font-size: 13px;
        color: var(--user-nft-list-item-text-muted-color);
        display: flex;
        align-items: center;
        white-space: nowrap;
        &__text {
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__icon {
            flex-shrink: 0;
            width: 1em;
            height: 1em;
            margin-left: 0.3em;
            fill: var(--blue-bright);
        }
    }
    &__btn {
        display: block;
        width: auto;
        max-width: 180px;
        line-height: 40px;
        padding: 0 15px;
        margin: 20px auto 0 auto;
        background: var(--big-blue-button-background);
        color: #FFF;
        font-weight: 500;
        font-size: 14px;
        border-radius: 6px;
        text-align: center;
        -webkit-transition: all .15s;
        -o-transition: all .15s;
        transition: all .15s;
    }

    &__btn:hover {
        text-decoration: none;
        background: var(--big-blue-button-hover-background);
    }
}
@media all and (max-width: 480px) {
    .user-nft {
        &__footer {
            padding: 10px;
        }
        &__name {
            font-size: 14px;
        }
        &__collection {
            font-size: 11px;
        }
    }
}
</style>
