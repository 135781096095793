<template>
    <section class="market-section">
        <section class="market-info-wrapper">
            <div class="market-info-section">
                <div class="market-info-item">
                    <overview />
                </div>
                <div class="market-info-item">
                    <trending />
                </div>
                <div class="market-info-item">
                    <tips />
                    <!--<advertisement />-->
                </div>
            </div>
        </section>
        <section>
            <type-filter v-bind:activeCategory="category" v-on:change-jetton-category="(c) => filterJettons(c)" />
        </section>

        <section class="card jetton-indexer-card">
            <div class="tx-history-wrap desktop-table">
                <table class="jetton-indexer-table">
                    <thead>
                        <tr>
                            <th class="jetton-idx">#</th>
                            <th class="jetton-m-cap" v-bind:colspan="isMobile ? 2 : 1">
                                <span v-if="isMobile">{{ $t('market_table.name') }}</span>
                            </th>
                            <th class="jetton-name" v-if="!isMobile">{{ $t('market_table.name') }}</th>
                            <th class="jetton-price">{{ $t('market_table.price') }}</th>
                            <th class="jetton-hour" v-if="!isMobile">{{ $t('common.day') }}</th>
                            <th class="jetton-day" v-if="!isMobile">{{ $t('common.week') }}</th>
                            <th class="jetton-week" v-if="!isMobile">{{ $t('common.month') }}</th>
                            <th class="jetton-mc" v-if="!isMobile">{{ $t('market_table.traders') }}</th>
                            <th class="jetton-volume" v-if="!isMobile">{{ $t('market_table.volume') }}</th>
                            <th v-if="!isMobile">{{ $t('market_table.last_week') }}</th>
                        </tr>
                    </thead>
                    <tbody v-if="tokens_data.length > 0">
                        <row-market v-for="(token, index) in tokens_data"  v-bind:key="`token-${token.jetton}`"
                            v-bind:index="index + 1" v-bind:img="token.img" v-bind:name="token.name"
                            v-bind:address="token.jetton" v-bind:symbol="token.symbol" v-bind:price="token.priceUsd"
                            v-bind:volume="token.volume24h" v-bind:chart-data="token.chart"
                            v-bind:traders="token.holders"
                            v-bind:priceChange24h="token.priceChange24h"
                            v-bind:priceChange1w="token.priceChange1w"
                            v-bind:priceChange1m="token.priceChange1m"
                            v-bind:chartLast7d="token.chartLast7d"
                            v-bind:chartLight="token.chartLight"
                            v-bind:chartDark="token.chartDark"
                        />
                    </tbody>
                    <!-- skeleton -->
                    <tbody v-else>
                        <template v-if="!isMobile">
                            <row-market-skeleton v-for="skeleton in 20" v-bind:key="`sk-${skeleton}`" />
                        </template>
                        <template v-else>
                            <row-market-skeleton-mobile v-for="skeleton_m in 20" v-bind:key="`skm-${skeleton_m}`" />
                        </template>
                    </tbody>
                </table>

                <ui-mugen-scroll
                    v-bind:handler="loadMore"
                    v-bind:shouldHandle="shouldHandleScroll"
                    v-bind:showButton="showPreloader"
                    v-bind:isLoading="isLoading"/>
            </div>
        </section>
    </section>
</template>

<script>
import { getTrendingJettonsList } from '~/api/dyor.js';
import Tips from '~/components/market/Blocks/Tips/Tips.vue';
import RowMarket from './RowMarket.vue';
import RowMarketSkeleton from './RowMarketSkeleton.vue';
import RowMarketSkeletonMobile from './RowMarketSkeletonMobile.vue';
import Trending from './Blocks/Trending/Trending.vue';
import Overview from './Blocks/Overview/Overview.vue';
// import Advertisement from './Blocks/Ads/Spintria.vue';
import TypeFilter from './JettonTypeFilter.vue';

export default {
    data() {
        return {
            tokens_data: [],
            hasMore: true,
            isLoading: true,
            offset: 0,
            category: 'jettons',
        };
    },

    computed: {
        shouldHandleScroll() {
            return !this.isLoading
                && this.hasMore
                && this.tokens_data.length > 0;
        },

        showPreloader() {
            return this.tokens_data.length > 0
                && this.hasMore;
        },
    },

    created() {
        this.category = this.$route.query?.sort || 'jettons';
        this.loadData();
    },

    metaInfo() {
        return {
            title: this.$t('market.meta.title'),
            meta: [
                {
                    property: 'description',
                    content: this.$t('market.meta.description'),
                },
            ],
        };
    },

    methods: {
        async loadData() {
            this.isLoading = true;
            const limit = 40;
            const offset = this.offset;

            try {
                const data = await getTrendingJettonsList(limit, offset, this.category);

                this.tokens_data.length = data.length;
                this.tokens_data = data;

                this.hasMore = data.length >= limit;
                this.offset += limit;
            } catch (error) {
                console.error('Error loading jettons:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async loadMore() {
            this.isLoading = true;
            const limit = 40;
            const offset = this.offset;

            try {
                const data = await getTrendingJettonsList(limit, offset, this.category);

                // this.tokens_data = data;
                this.tokens_data.push(...data);

                this.hasMore = data.length >= limit;
                this.offset += limit;
            } catch (error) {
                console.error('Error loading more transactions:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async filterJettons(category) {
            this.tokens_data = [];
            this.offset = 0;
            this.category = category;

            await this.loadData();
        },
    },

    components: {
        Tips,
        RowMarket,
        RowMarketSkeleton,
        RowMarketSkeletonMobile,
        Trending,
        Overview,
        // Advertisement,
        TypeFilter,
    },
};
</script>

<style lang="scss">
.market-information {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px 18px;
    margin-bottom: 22px;
}

.market-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 26px 16px;
    flex: 1;

    header {
        margin-top: 3px;
        color: var(--card-header-color);
    }

    &__value {
        font-size: 34px;
    }
}

.jetton-indexer-card {
    border-radius: 0 0 12px 12px !important;
}

.jetton-indexer-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    button {
        background-color: var(--card-background);
        background-clip: border-box;
        border: 1px solid var(--card-border-color);
        border-radius: .5rem;
        box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
        font-size: 14px;
        color: var(--body-muted-text-color);
        padding: 6px 12px;
        cursor: pointer;
    }

    button:not(:last-child) {
        margin-right: 12px;
    }

    .active {
        background-color: var(--big-blue-button-background);
        border-color: var(--big-blue-button-background);
        color: white;
    }
}

.jetton-indexer-table {
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
    border-radius: 0;

    tbody {
        padding: 18px 0;
    }

    thead {
        background-color: var(--tx-table-thead-alt-background);
        color: #6c757e;
        border: none;
    }

    td,
    th {
        white-space: nowrap;
        overflow: hidden;
        border-top: 1px solid var(--card-border-color);
        font-size: 14px;
        max-width: 250px;
    }

    th {
        padding: 10px 0;
        font-weight: 500;
        border: none;
    }

    td {
        p {
            margin: 0;
        }

        p:first-child {
            padding-bottom: 3px;
        }
    }

    tr>th:first-child,
    tr>td:first-child {
        padding-left: 18px;
    }

    tr>th:last-child,
    tr>td:last-child {
        padding-right: 18px;
    }

    &__icon {
        width: 32px;
        height: 32px;
    }

    .jetton-idx {
        width: 26px;
        text-align: left;
    }

    .jetton-m-cap {
        width: auto;
        text-align: left;
        padding: 0 12px;
    }

    .jetton-name {
        text-align: left;
    }

    .jetton-price {
        text-align: right;
        width: 82px;
    }

    .jetton-hour {
        text-align: center;
        padding-left: 30px;
    }

    .jetton-day {
        text-align: center;
    }

    .jetton-week {
        text-align: center;
    }

    .jetton-mc {
        text-align: center;
    }

    .jetton-volume {
        text-align: center;
    }

    .jetton-last {
        width: 120px;
    }
}

.market-info-section {
    gap: 20px;
    display: flex;
    margin-bottom: 20px;
}

.market-info-item {
    width: 33.333%;
}

.market-info-section .market-info-block {
    margin-top: 0 !important;
}

.market-info-block {
    height: 100%;

    .card-market-box {
        // align-items: flex-start;
        // display: flex;
        // flex: 1;
        // flex-direction: column;
    }

    .card-market-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .card-market-title {
        font-weight: 500;
        text-transform: uppercase;
    }

    .card-market-title,
    .card-market-period {
        color: var(--card-header-tab-color);
        padding: 12px 16px 0 16px;
    }
}

@media screen and (max-width: 991px) {
    .market-info-wrapper::-webkit-scrollbar {
        display: none;
    }

    .market-info-section {
        display: flex;
        position: relative;
    }

    .market-info-wrapper {
        margin: 0 -8px;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-left: 8px;
        padding-right: 8px;
        scroll-snap-type: x mandatory;
        scrollbar-color: transparent transparent;
        scrollbar-width: none;
    }

    .market-info-section .market-info-item {
        width: inherit;
        min-width: 92%;
    }

    .market-info-item {
        scroll-snap-align: center;
    }

    .market-info-section .market-info-item:last-child {
        padding-right: 8px;
    }
}

@media screen and (max-width: 599px) {
    .jetton-indexer-table {
        td {
            white-space: normal;
        }

        &__icon {
            width: 40px;
            height: 40px;
        }

        tr>th:first-child,
        tr>td:first-child {
            padding-left: 12px;
        }

        tr>th:last-child,
        tr>td:last-child {
            padding-right: 12px;
            text-align: right !important;
        }
    }

    .jetton-indexer-table {
        border-radius: 0;
    }

    .market-header {
        padding: 12px 8px;
        margin-bottom: 5px;
    }
}
</style>
