<template>
    <div class="event-name-simple tx-table__cell">
        <template v-if="!is_success">
            <div class="event-name-simple__icon event-name-simple--failed">
                <icon-tx-error />
            </div>
            <span class="event-name-simple--failed">{{$t('tx.status_failed')}}</span>
        </template>
        <template v-else>
            <div class="event-name-simple__icon">
                <component v-bind:is="getIcon"></component>
            </div>
            <!-- Exception for nft transfers -->
            <span v-if="event === 'nft_transfer'">
                <span v-if="is_out">{{ $t(`events.sent_nft`) }}</span>
                <span v-else>{{ $t(`events.received_nft`) }}</span>
            </span>
            <span v-else>
                {{ $t(`events.${event}`) }}
            </span>
        </template>
    </div>
</template>

<script>
import IconIn from '@img/icons/tonscan/transactions/tx-in.svg?vue';
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconBurn from '@img/icons/tonscan/transactions/tx-burn.svg?vue';
import IconJettonMint from '@img/icons/tonscan/transactions/tx-jetton-mint.svg?vue';
import IconNftIn from '@img/icons/tonscan/transactions/tx-nft-in.svg?vue';
import IconNftOut from '@img/icons/tonscan/transactions/tx-nft-out.svg?vue';
import IconNftBuy from '@img/icons/tonscan/transactions/tx-nft-buy.svg?vue';
import IconNftMint from '@img/icons/tonscan/transactions/tx-nft-mint.svg?vue';
import IconSwap from '@img/icons/tonscan/transactions/tx-swap.svg?vue';
import IconContractDeploy from '@img/icons/tonscan/transactions/tx-contract-deploy.svg?vue';
import IconSmartContractExec from '@img/icons/tonscan/transactions/tx-contract.svg?vue';
import IconStakeIn from '@img/icons/tonscan/transactions/tx-stake-in.svg?vue';
import IconStakeOut from '@img/icons/tonscan/transactions/tx-stake-out.svg?vue';
import IconStakeDeposit from '@img/icons/tonscan/transactions/tx-stake-deposit.svg?vue';
import IconStakeWithdraw from '@img/icons/tonscan/transactions/tx-stake-withdraw.svg?vue';
import IconAuctionBid from '@img/icons/tonscan/transactions/tx-bid.svg?vue';
import IconSubscribe from '@img/icons/tonscan/transactions/tx-sub.svg?vue';
import IconUnsubscribe from '@img/icons/tonscan/transactions/tx-unsub.svg?vue';
import IconChain from '@img/icons/tonscan/chain-16.svg?vue';
import IconDomainRenew from '@img/icons/tonscan/domain_renew.svg?vue';
import IconTxError from '@img/icons/tonscan/transactions/tx-failed.svg?vue';

/* eslint-disable "quote-props" */
const iconMap = Object.freeze({
    received_ton: IconIn,
    sent_ton: IconOut,
    received_jetton: IconIn,
    sent_jetton: IconOut,
    auction_bid: IconAuctionBid,
    contract_deploy: IconContractDeploy,
    deploy_nft: IconNftMint,
    stake_deposit: IconStakeDeposit,
    renew_dns: IconDomainRenew,
    election_deposit: IconStakeOut,
    election_recover: IconStakeIn,
    jetton_mint: IconJettonMint,
    jetton_burn: IconBurn,
    jetton_swap: IconSwap,
    nft_purchase: IconNftBuy,
    received_nft: IconNftIn,
    sent_nft: IconNftOut,
    smart_contract_exec: IconSmartContractExec,
    subscribe: IconSubscribe,
    unsubscribe: IconUnsubscribe,
    stake_withdrawal: IconStakeWithdraw,
    stake_withdrawal_request: IconStakeIn,
    change_dns: IconDomainRenew,
    delete_dns: IconDomainRenew,
    dex_deposit_liquidity: IconStakeDeposit,
    dex_withdraw_liquidity: IconStakeWithdraw,
});
export default {
    props: ['event', 'is_success', 'is_out'],

    computed: {
        getIcon() {
            if (this.event === 'nft_transfer') {
                return this.is_out ? IconNftOut : IconNftIn;
            }
            return iconMap[this.event] || IconChain;
        },
    },

    components: {
        IconTxError,
    },
};
</script>

<style lang="scss">
.event-name-simple{
    padding: 4px 10px 4px 6px;
    font-size: 13px;
    border-radius: 8px;
    color: inherit;
    display: flex;
    align-items: center;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }

    &__icon {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 4px;

        &--chain {
            transform: scale(.8);
        }
    }

    &--failed {
        color: var(--badge-red-color);
    }
}
</style>
