<template>
    <div class="card" id="unlocks" style="height: 100%;max-width: 100%;">
        <div class="card-title" style="border: none;position: relative; overflow: visible">
            <div>
                {{$t('stats.unlocks')}}
                <span class="muted">
                    <icon-title-help class="unlocks-tooltip__icon"
                        v-on:mouseover.native="showHelpTooltip = true"
                        v-on:mouseleave.native="showHelpTooltip = false"
                    />
                </span>
                <div v-if="showHelpTooltip" class="unlocks-tooltip-wrapper">
                    {{ $t('stats.unlocks_tooltip') }}
                </div>
            </div>
        </div>

        <div class="stats-chart" style="padding-left: 12px;">
            <line-chart
                style="flex-grow: 1"
                v-bind:labels="labels"
                v-bind:datasets="datasets"
                hide-legend
                showYear
            />
        </div>
    </div>
</template>

<script>
import { AMOUNT_OF_DATA_ON_MOBILE, AMOUNT_OF_DATA_ON_TABLET } from '~/helpers.js';
import LineChart from '~/lib/Lightchart/UiChartLine.vue';
import { decimateData } from '~/decimation.js';
import IconTitleHelp from '@primer/octicons/build/svg/info-16.svg?vue';
import unlocks from '../../../json/unlocks.json';

export default {
    data() {
        return {
            labels: undefined,
            datasets: undefined,
            showHelpTooltip: false,
        };
    },

    mounted() {
        this.getData();
    },

    computed: {
        parsedChartLabels() {
            if (!this.labels) {
                return undefined;
            }

            switch (true) {
                case this.isMobile: return decimateData(this.labels, AMOUNT_OF_DATA_ON_MOBILE);
                case this.isTablet: return decimateData(this.labels, AMOUNT_OF_DATA_ON_TABLET);
                default: return this.labels;
            }
        },
    },

    methods: {
        getData() {
            const labels = [];
            const dataset = [];

            Object.keys(unlocks).forEach((timestamp) => {
                const date = Number.parseInt(timestamp, 10);
                labels.push(date * 1000);
                dataset.push(unlocks[date].ton);
            });

            this.datasets = [{
                data: dataset,
                label: this.$t('stats.total_unlocked'),
                suffix: ' TON',
            }];
            this.labels = labels;
        },
    },

    components: {
        LineChart,
        IconTitleHelp,
    },
};
</script>

<style lang="scss">
.unlocks-tooltip__icon {
    fill: currentColor;
    width: 14px;
    height: 14px;
    margin: -4px 0 -4px 3px;
    transform: translateY(-2px);
    cursor: pointer;
    transition: .15s color;

    &:hover {
        color: var(--card-header-color);
        text-decoration: none;
    }
}

.unlocks-tooltip-wrapper {
    top: 45px;
    left: 12px;
    position: absolute;
    padding: 12px 14px;
    color: var(--body-text-color);
    border-radius: 12px;
    font-size: 13px;
    text-transform: none;
    background: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    max-width: 290px;
    z-index: 1001;
    font-weight: 400;
}
</style>
