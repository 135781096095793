<template>
    <div class="addressbook-dropdown" v-bind:class="{'addressbook-dropdown--index': $route.name === 'index' }">
        <div v-if="!isEdit" class="addressbook-dropdown__header">
            <div style="font-weight: 500">{{ $t('local_addressbook.favorite') }}</div>
            <div v-if="canSave && !isAlreadyAdded" v-on:click="saveItem" class="addressbook-dropdown-save-button">
                {{ $t('local_addressbook.save_address') }}
            </div>
            <div v-if="canSave && isAlreadyAdded" v-on:click="removeItem" class="addressbook-dropdown-save-button">
                {{ $t('local_addressbook.remove_address') }}
            </div>
        </div>

        <template v-if="addressbook.length > 0 && !isEdit">
            <desktop-addressbook-item
                v-for="(item, index) in addressbook"
                v-bind:address="item.address"
                v-bind:name="item.name"
                v-bind:index="index"
                v-bind:type="item.type"
                v-on:edit-address-name="editName"
                v-bind:key="`local_ab_item_${index}`"
            />
        </template>

        <div v-else-if="isEdit">
            <div class="addressbook-dropdown__header addressbook-dropdown-back-button">
                <a v-on:click="cancelEdit">
                    <icon-back />{{ $t('local_addressbook.back') }}
                </a>
                <div>
                    <ui-address
                        class="muted"
                        style="width: 160px"
                        v-bind:address="editObject.address"
                        v-bind:disabled="true"
                        v-bind:hide-name="true"
                    />
                </div>
            </div>
            <div style="padding: 0 4px 4px">
                <input
                    class="addressbook-dropdown-input"
                    type="text"
                    v-bind:placeholder="$t('local_addressbook.title')"
                    spellcheck="false"
                    autocomplete="off"
                    v-model.trim="newName"
                    v-on:keyup.enter="saveEdited"
                    v-on:keyup.esc="cancelEdit"
                    autofocus
                    maxlength="32"
                />
                <button v-on:click="saveEdited" class="addressbook-dropdown-button">
                    {{ $t('local_addressbook.save') }}
                </button>
            </div>
        </div>

        <div v-else class="addressbook-dropdown__empty muted">
            {{ $t('local_addressbook.empty') }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import IconBack from '@img/icons/tonscan/arrow-small-left.svg?vue';
import DesktopAddressbookItem from '~/components/address/Addressbook/DesktopAddressbookItem.vue';

const saveTypes = [
    'address',
    'jetton',
    'nominator',
    'vesting',
    'locker',
    'nft',
    'single_nominator',
];

export default {
    data() {
        return {
            isEdit: false,
            editObject: undefined,
            newName: undefined,
        };
    },

    computed: {
        isAlreadyAdded() {
            return !!this.addressbook.find(i => i.address === this.presaveAddressbookItem.address);
        },
        addressbook() {
            return this.appLocalAddressbook;
        },
        canSave() {
            const routerName = this.$route.name;

            return saveTypes.includes(routerName);
        },
        ...mapState(['appLocalAddressbook', 'presaveAddressbookItem']),
    },

    methods: {
        saveItem() {
            this.$store.dispatch('rememberAddressbookItem');
        },
        removeItem() {
            this.$store.dispatch('forgetAddressbookItem', { address: this.presaveAddressbookItem.address });
        },
        editName(index) {
            this.isEdit = true;
            this.editObject = this.addressbook[index];
            if (this.addressbook[index]?.name > 0) {
                this.newName = '';
            } else {
                this.newName = this.addressbook[index]?.name || '';
            }
        },
        cancelEdit() {
            this.isEdit = false;
            this.editObject = undefined;
            this.newName = undefined;
        },
        saveEdited() {
            this.$store.dispatch('editAddressbookItem', { address: this.editObject.address, newName: this.newName });
            this.cancelEdit();
        },
    },

    components: {
        IconBack,
        DesktopAddressbookItem,
    },
};
</script>

<style lang="scss">
.addressbook-dropdown {
    position: absolute;
    top: 45px;
    right: 30px;
    z-index: 1002;
    background: var(--card-background);
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    border-radius: 12px;
    width: 320px;
    box-sizing: border-box;
    padding: 8px;
    font-size: 14px;
    max-height: 298px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    &--index {
        top: 38px;
        right: -8px;
    }

    &__header {
        box-sizing: border-box;
        width: calc(100% + 16px);
        margin-left: -8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 3px 14px 10px;
        margin-bottom: 6px;
        border-bottom: 1px solid var(--card-border-color);
        user-select: none;
    }

    &__empty {
        padding: 20px 0;
        text-align: center;
        user-select: none;
    }
}

.addressbook-dropdown-back-button {
    svg {
        margin-right: 4px;
        height: 9px;
        width: auto;
    }

    a {
        cursor: pointer;
        color: var(--blue-bright);

        &:hover {
            text-decoration: underline;
        }
    }
}

.addressbook-dropdown-input {
    box-sizing: border-box;
    width: 100%;
    padding: 8px 10px;
    color: inherit;
    position: relative;
    border-radius: 12px;
    background: var(--indexpage-search-background-color);
    border: 2px solid var(--card-border-color);

    &:focus {
        border-color: var(--app-search-field-focused-border-color) !important;
    }
}

.addressbook-dropdown-button {
    display: block;
    width: 100%;
    line-height: 32px;
    margin: 8px 0 0 0;
    background: var(--big-blue-button-background);
    cursor: pointer;
    color: #FFF;
    font-weight: 500;
    font-size: 14px;
    border-radius: 6px;
    text-align: center;
    -webkit-transition: all .15s;
    -o-transition: all .15s;
    transition: all .15s;
    user-select: none;

    &:hover {
        text-decoration: none;
        background: var(--big-blue-button-hover-background);
    }
}

.addressbook-dropdown-save-button {
    cursor: pointer;
    color: var(--blue-bright);
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}
</style>
